import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const trainRouter: RouteConfig[] = [
  {
    path: '/trains',
    component: Layout,
    name: 'train',
    redirect: '/trains/index',
    meta: {
      title: '培训管理'
    },
    children: [
      {
        path: 'index',
        name: 'train-list',
        component: () => import(/* webpackChunkName: "train" */'@/views/train/index.vue'),
        meta: {
          title: '培训管理'
        }
      },
      {
        path: 'trainRecord/:id',
        name: 'trainRecord',
        component: () => import(/* webpackChunkName: "train" */'@/views/train/trainRecord.vue'),
        meta: {
          title: '培训记录管理'
        }
      },
      {
        path: 'create',
        name: 'trainCreate',
        component: () => import(/* webpackChunkName: "train" */'@/views/train/edit.vue'),
        meta: {
          title: '创建培训'
        }
      },
      {
        path: 'edit/:id',
        name: 'trainEdit',
        component: () => import(/* webpackChunkName: "train" */'@/views/train/edit.vue'),
        meta: {
          title: '编辑培训'
        }
      },
      {
        path: 'detail/:id',
        name: 'trainDetail',
        component: () => import(/* webpackChunkName: "train" */'@/views/train/detail.vue'),
        meta: {
          title: '培训详情'
        }
      }
    ]
  }
]


export default trainRouter;
