import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'
import { UserModule } from "@/store/modules/user";

import adminRouter from '@/router/modules/admin';
import cmsRouter from '@/router/modules/cms';
import projectRouter from '@/router/modules/project';
import ouRouter from '@/router/modules/ou';
import auditRouter from "@/router/modules/audit";
import formRouter from "@/router/modules/form";

import partnerInviteRouter from "@/router/modules/partnerInvite";
import notifyRouter from "@/router/modules/notify";
import organizationRouter from "@/router/modules/organization";
import organizationApplyRouter from "@/router/modules/organizationApply";
import financeRouter from "@/router/modules/finance";
import workbenchRouter from "@/router/modules/workbench";
import accountRoutes from "@/router/modules/account";
import {getUser} from "@/utils/request";
import Cookies from "js-cookie";
import trainRouter from "@/router/modules/trains";
import volunteerRouter from "@/router/modules/volunteer";



Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export const constantRoutes: RouteConfig[] = [

  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true, auth: false },
    children: [{
      path: '/redirect/:path(.*)',
      component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
    }]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { hidden: true, auth: false }
  },

  {

    path: '/',
    component: Layout,
    redirect: '/welcome',
    meta: {
      title: '',
    },
    children: [
      {
        path: 'welcome',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/welcome.vue'),
        meta: {
          title: '首页',
        }
      },
    ]
  },

  {

    path: '/',
    component: Layout,
    redirect: '/externalDashboard',
    meta: {
      title: 'Dashboard',
    },
    children: [
      {
        path: 'externalDashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/external.vue'),
        meta: {
          title: '外部项目Dashboard',
        }
      },
      {
        path: 'internalDashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/internal.vue'),
        meta: {
          title: '内部项目Dashboard',
        }
      },
    ]
  }
  ,


]

// 动态加载菜单
export const asyncRouter: RouteConfig[] = [
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  }
]

const router = new Router({
  mode: 'history',  // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,

  routes: [
    ...constantRoutes,
    ...adminRouter,
    ...ouRouter,
    ...cmsRouter,
    ...financeRouter,
    ...asyncRouter,
    ...auditRouter,
    ...notifyRouter,
    ...organizationRouter,
    ...partnerInviteRouter,
    ...organizationApplyRouter,
    ...projectRouter,
    ...workbenchRouter,
    ...accountRoutes,
    ...volunteerRouter,
    ...trainRouter,
    ...formRouter,]
});

router.beforeEach(((to, from, next) => {
  const token = Cookies.get('accessToken');
  if (token) {
    if(!UserModule.getFullName) {
      getUser().then(()=>{
        next()
      })
    } else {
      next();
    }
  } else {
     if((to.meta)?.auth !== false){
       next("/account/login");
        // getUser().then((res)=>{
        //   if(res){
        //     next();
        //   }
        //   else{
        //     next("/account/login");
        //   }
        // }).catch(()=>{
        //   next('/')
        // })
     } else {
        next();
     }

    // if (to.meta.auth !== false) {
    //   next();
    //   // let location = window.location;
    //   // let loginUrl = `${location.protocol}//${location.hostname}:${location.port}${from.fullPath}`;
    //   // window.location.href = process.env.VUE_APP_BASE_API+'/account/login?returnUrl='+encodeURIComponent(loginUrl);
    // } else {
    //   next();
    // }
  }
}))

export default router;
