import * as _ from 'lodash'
import {AuditNodeCreateOrEditDto} from "@/api/appService";

export interface INodesBlock {
  items: AuditNodeCreateOrEditDto[]
}



export function createNodes(array: AuditNodeCreateOrEditDto[]
) {
  const result: INodesBlock[] = [];
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (!result[element.index!]) {
      result[element.index!] = { items: [] }
    }
    result[element.index!].items.push(element);
  }
  return result;
}

export function flatenNodes(array: INodesBlock[]) {
  const result: AuditNodeCreateOrEditDto[] = [];
  array = array.filter(x => x.items.length > 0);
  for (let i = 0; i < array.length; i++) {
    array[i].items.forEach(x => {
      result.push({ ...x, index: i });
    });
  }
  return result;
}


export function createTableTree(
  array: any[],
  parentIdProperty: string,
  idProperty: string,
  parentIdValue: any,
  childrenProperty: string
) {
  const tree: any[] = []
  const nodes = _.filter(array, [parentIdProperty, parentIdValue])

  _.forEach(nodes, (node:any) => {
    const newNode = Object.assign({}, node, { hasChildren: false, isLeaf: true });

    const _children = createTableTree(
      array,
      parentIdProperty,
      idProperty,
      node[idProperty],
      childrenProperty
    )

    newNode[childrenProperty] = _children;

    if (_children.length > 0) newNode['hasChildren'] = true
    if (_children.length > 0) newNode['isLeaf'] = false
    tree.push(newNode)
  })
  return tree
}

// @ts-ignore
export function createSelectTree(
  array: any[],
  parentIdProperty: string,
  idProperty: string,
  parentIdValue: any,
  labelProperty: string,
  level: number = 0
) {

  if (array === null || array === undefined || array.length === 0) {
    return [];
  }

  const result = [];
  for (const item of array) {
    if (item[parentIdProperty] === parentIdValue) {
      item[labelProperty] = createPrefix(level) + item[labelProperty];
      result.push(item);
      result.push(...createSelectTree(array, parentIdProperty, idProperty, item[idProperty], labelProperty, level + 1));
    }
  }

  return result;


}

function createPrefix(level: number) {
  let result = '';
  for (let i = 0; i < level; i++) {
    result += '—';
  }
  return result;
}

export interface INgNode<T> {
  label:string;
  id:any,
  expanded: boolean;
  isLeaf:boolean;
  disabled:boolean,
  hidden?:boolean;
  key:string;
  parentNode?:INgNode<T>,
  data:T,
  children?:INgNode<T>[]
}

export interface ICheckDisable<T> {
  (item:T):boolean;
}

export interface ICreateNgTreeArgument<T> {
  array:T[];
  labelProperty:string;
  idProperty:string;
  keyProperty:string;
  parentIdValue:string;
  parentIdProperty:string;
  childrenProperty:string;
  fieldMappings:any;
  disabled: boolean | ICheckDisable<T>,
  parentNode?:INgNode<T>;

}

export function createTree<T>(argument:ICreateNgTreeArgument<T>) : INgNode<T>[]{
  const tree: INgNode<T>[] = [];

  const nodes = _.filter(argument.array, [argument.parentIdProperty, argument.parentIdValue]);

  _.forEach(nodes, (node:any) => {
    const newNode: INgNode<T> = {
      label: (node as any)[argument.labelProperty],
      id: (node as any)[argument.idProperty],
      expanded: true,
      isLeaf: true,
      disabled:(typeof argument.disabled === 'boolean'?argument.disabled:argument.disabled(node)),
      data:node,
      children:undefined,
      parentNode:argument.parentNode,
      hidden:false,
      key:((node as any)[argument.keyProperty]).toString()
    };



    const _children: INgNode<T>[] = createTree({
        array:argument.array,
        parentIdProperty:argument.parentIdProperty,
        idProperty:argument.idProperty,
        parentIdValue:(node as any)[argument.idProperty],
        childrenProperty:argument.childrenProperty,
        fieldMappings:argument.fieldMappings,
        disabled:argument.disabled,
        labelProperty:argument.labelProperty,
        parentNode:newNode,
        keyProperty: argument.keyProperty
      }

    );

    if(_children&&_children.length>0){
      newNode.children = _children;
    } else {
      (newNode as any)[argument.childrenProperty] = undefined;
    }


    if (_children.length > 0){
      newNode['isLeaf'] = false;
    }

    tree.push(newNode)
  });
  return tree
}

export function createNgTree<T>(array: T[],
                                parentIdProperty: string,
                                idProperty: string,
                                parentIdValue: any,
                                childrenProperty: string,
                                fieldMappings: any,
                                disabled: boolean | ICheckDisable<T>,
                                keyProperty:string,
                                parentNode?:INgNode<T>,) : INgNode<T>[] {

  return createTree({
    array:array,
    parentIdProperty:parentIdProperty,
    idProperty:idProperty,
    parentIdValue:parentIdValue,
    childrenProperty:childrenProperty,
    fieldMappings:fieldMappings,
    disabled:disabled,
    parentNode:parentNode,
    keyProperty:keyProperty?keyProperty:idProperty,
    labelProperty:'displayName'});
}

export function ngTreeToDictionary<T>(treeData:INgNode<T>[]):{[key:string]:INgNode<T>}{
  let result :{[key:string]:INgNode<T>} = {};
  treeData.forEach(node=>{
    let id = node!.id.toString() as string;
    result[id] = node;

    const children  = ngTreeToDictionary(node.children!);

    result = {...result,...children};
  });

  return result;

}

export function ngTreeToArray<T>(treeData?:INgNode<T>[]):INgNode<T>[]{
  let result:INgNode<T>[] = [] ;
  if(treeData){
    treeData.forEach(node=>{
      result.push(node);
      result = [...ngTreeToArray(node.children)];
    });
  }
  return result;
}
export function setNgNodeProperty<T>(treeData:INgNode<T>[],action:(arg:INgNode<T>)=>unknown){
  treeData.forEach(item=>{
    action(item);
    if(item.children){
      setNgNodeProperty(item.children,action);
    }
  })
}




export function createOUTree(array: any,
                             parentIdProperty: any,
                             idProperty: any,
                             parentIdValue: any,
                             childrenProperty: any,
                             disabled: any,
                             ext: boolean = false) {
  const tree: any[] = []

  let nodes = _.filter(array, [parentIdProperty, parentIdValue])
  _.forEach(nodes, (node:any) => {
    const newNode: any = {
      label: `${node.displayName}`,
      data: node,
      id: node[idProperty],
      expanded: true,
      disabled
    }
    if (ext) {
      newNode.label += `(${node.memberCount}成员)`;
    }
    newNode[childrenProperty] = createOUTree(
      array,
      parentIdProperty,
      idProperty,
      node[idProperty],
      childrenProperty,
      disabled
    )
    tree.push(newNode)
  })
  return tree
}

export function getKeys(array: any[]) {
  if (array) {
    return Array.from(
      new Set([
        'Pages',
        ..._getKeys(array),
        ...array
      ])
    )
  } // 去重
  return []
}

function _getKeys(array: any[]) {
  let _result: any[] = [];
  [...array].forEach(z => {
    const _index = z.lastIndexOf('.')
    if (_index > 0) {
      const _p = z.substring(0, _index)
      _result = [..._result, _p]
      _result = [..._result, ..._getKeys(_p)]
    }
  })
  return _result
}

export function rebuildKeys(array: any[]) {
  _.remove(array, (z:any) => {
    const _l = array.filter(zz => zz.indexOf(z) > -1).length
    return _l > 1
  })
  return array
}

// 获取栏目id数组
export function getCategoryIdList(categories:any[], categoryId:number) {
  let result:number[] = [categoryId];
  let item = findCategory(categories, categoryId);
  while(item?.parentId){
    result.push(item.parentId)
    item = findCategory(categories, item.parentId);
  }
  return result.reverse();
}

function findCategory(categories:any[], categoryId:number){
  for(let i = 0,len = categories.length;i<len;i++){
    let item = categories[i];
    if(item.id === categoryId){
      return item;
    }
  }
  return undefined;
}
