/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export class IList<T> extends Array<T> {}
export class List<T> extends Array<T> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount: number;
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount!: number;
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static isTenantAvailable(
    params: {
      /** requestBody */
      body?: IsTenantAvailableInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IsTenantAvailableOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/IsTenantAvailable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /** requestBody */
      body?: RegisterInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegisterOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 登录
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginPostReqDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginPostRespDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AlbumService {
  /**
   * 获取相册信息
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建相册
   */
  static create(
    params: {
      /** requestBody */
      body?: AlbumCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新相册
   */
  static update(
    params: {
      /** requestBody */
      body?: AlbumCreateUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除相册
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有相册
   */
  static getAllAlbums(
    params: {
      /**  */
      name?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/GetAllAlbums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传照片到相册
   */
  static uploadPhoto(options: IRequestOptions = {}): Promise<AlbumPhotoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/UploadPhoto';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除照片
   */
  static deletePhoto(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/DeletePhoto';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取照片列表
   */
  static getAllPhotos(
    params: {
      /**  */
      albumId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumPhotoDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/GetAllPhotos';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AlbumId: params['albumId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 将照片转到新相册
   */
  static moveToNewAlbum(
    params: {
      /** requestBody */
      body?: AlbumPhotoMoveInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/MoveToNewAlbum';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 将照片设为相册封面
   */
  static setAsCover(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/SetAsCover';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重命名照片
   */
  static renamePhoto(
    params: {
      /** requestBody */
      body?: RenameAlbumPhotoInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AlbumPhotoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/RenamePhoto';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static download(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Album/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AreaService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AreaCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AreaCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      areaName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AreaName: params['areaName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AttachmentService {
  /**
   *
   */
  static getAttachments(
    params: {
      /**  */
      hostId?: string;
      /**  */
      hostType?: AttachmentHostType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAttachments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { hostId: params['hostId'], hostType: params['hostType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取阿里云STS临时授权Token
   */
  static getAliyunOssStsToken(options: IRequestOptions = {}): Promise<AliyunOssStsDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAliyunOssSTSToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAttachment(
    params: {
      /** requestBody */
      body?: AttachmentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadAttachment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传到临时目录
   */
  static uploadToTemp(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadToTemp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传到oss
   */
  static uploadToOss(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadToOss';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载附件
   */
  static download(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 激活
   */
  static active(
    params: {
      /** requestBody */
      body?: GuidEntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Active';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 此记录当前用户是否可以进行审核
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: CanAuditRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditNodePreDefineService {
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditNodePreDefineCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditNodePreDefineCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BankReconciliationService {
  /**
   * 银行对账收入统计
   */
  static getBankReconciliationIncomeReport(
    params: {
      /**  */
      year?: number;
      /** 账号 */
      bankAccount?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalMonthlyItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankReconciliation/GetBankReconciliationIncomeReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Year: params['year'], BankAccount: params['bankAccount'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 银行对账支出统计
   */
  static getBankReconciliationExpenseReport(
    params: {
      /**  */
      year?: number;
      /** 账号 */
      bankAccount?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalMonthlyItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BankReconciliation/GetBankReconciliationExpenseReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Year: params['year'], BankAccount: params['bankAccount'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BeneficiaryService {
  /**
   * 获取受益人列表(不受机构限制)
   */
  static getListForAll(
    params: {
      /** 个案编号 */
      code?: string;
      /** 姓名 */
      realName?: string;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetListForAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        RealName: params['realName'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: BeneficiaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: BeneficiaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkExist(
    params: {
      /** requestBody */
      body?: BeneficiaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/CheckExist';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 个案编号 */
      code?: string;
      /** 姓名 */
      realName?: string;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        RealName: params['realName'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 个案编号 */
      code?: string;
      /** 姓名 */
      realName?: string;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeneficiaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Beneficiary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Code: params['code'],
        RealName: params['realName'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BeneficiaryServiceRecordService {
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 服务记录类型 */
      serviceRecordType?: ServiceRecordType;
      /** 受益人名称 */
      beneficiaryName?: string;
      /** 学校名称 */
      schoolName?: string;
      /** 项目名称 */
      projectName?: string;
      /** 项目Id */
      projectId?: number;
      /** 受益人Id */
      beneficiaryId?: number;
      /** 机构Id */
      organizationUnitId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceRecordType: params['serviceRecordType'],
        BeneficiaryName: params['beneficiaryName'],
        SchoolName: params['schoolName'],
        ProjectName: params['projectName'],
        ProjectId: params['projectId'],
        BeneficiaryId: params['beneficiaryId'],
        OrganizationUnitId: params['organizationUnitId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ServiceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBeneficiaryRecordList(
    params: {
      /**  */
      beneficiaryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectServiceItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/GetBeneficiaryRecordList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { beneficiaryId: params['beneficiaryId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: ServiceRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 服务记录类型 */
      serviceRecordType?: ServiceRecordType;
      /** 受益人名称 */
      beneficiaryName?: string;
      /** 学校名称 */
      schoolName?: string;
      /** 项目名称 */
      projectName?: string;
      /** 项目Id */
      projectId?: number;
      /** 受益人Id */
      beneficiaryId?: number;
      /** 机构Id */
      organizationUnitId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ServiceRecordType: params['serviceRecordType'],
        BeneficiaryName: params['beneficiaryName'],
        SchoolName: params['schoolName'],
        ProjectName: params['projectName'],
        ProjectId: params['projectId'],
        BeneficiaryId: params['beneficiaryId'],
        OrganizationUnitId: params['organizationUnitId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/BeneficiaryServiceRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CapitalExpenseRecordAppserviceService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取变更历史记录
   */
  static getRecordChangeList(
    params: {
      /**  */
      recordId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetRecordChangeList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { recordId: params['recordId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContractNoInformation(
    params: {
      /**  */
      contractNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractNoInformationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetContractNoInformation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contractNo: params['contractNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取内部项目预算项及实际使用金额
   */
  static getInternalBudgetItemAndUseRecord(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalBudgetItemAndUseRecordDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetInternalBudgetItemAndUseRecord';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 保存变更申请
   */
  static changeApply(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/ChangeApply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消变更申请
   */
  static cancelChangeApply(
    params: {
      /** requestBody */
      body?: CapitalExpenseRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/CancelChangeApply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectReportList(
    params: {
      /**  */
      projectName?: string;
      /**  */
      year?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      projectType?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetProjectReportList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        Year: params['year'],
        ProjectSeriesId: params['projectSeriesId'],
        ProjectType: params['projectType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectRepprtDetailList(
    params: {
      /**  */
      projectName?: string;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      minAmount?: number;
      /**  */
      maxAmount?: number;
      /**  */
      projectId?: number;
      /**  */
      itemName?: string;
      /**  */
      remark?: string;
      /**  */
      summary?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectDetailReprotDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetProjectRepprtDetailList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        MinAmount: params['minAmount'],
        MaxAmount: params['maxAmount'],
        ProjectId: params['projectId'],
        ItemName: params['itemName'],
        Remark: params['remark'],
        Summary: params['summary'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExpenseRecordDetailByYear(
    params: {
      /**  */
      isInternal?: boolean;
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDetail[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetExpenseRecordDetailByYear';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { isInternal: params['isInternal'], year: params['year'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 保存支付时间
   */
  static savePayDate(
    params: {
      /**  */
      id?: number;
      /**  */
      payDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/SavePayDate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], payDate: params['payDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 收款单位 */
      payeeName?: string;
      /** 开户银行 */
      payeeBankAccount?: string;
      /** 付款方式 */
      payType?: PayType;
      /** 合同编号 */
      contractNo?: string;
      /** 开始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /**  */
      projectName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PayeeName: params['payeeName'],
        PayeeBankAccount: params['payeeBankAccount'],
        PayType: params['payType'],
        ContractNo: params['contractNo'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectName: params['projectName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 收款单位 */
      payeeName?: string;
      /** 开户银行 */
      payeeBankAccount?: string;
      /** 付款方式 */
      payType?: PayType;
      /** 合同编号 */
      contractNo?: string;
      /** 开始日期 */
      startDate?: string;
      /** 截止日期 */
      endDate?: string;
      /**  */
      projectName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalExpenseRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalExpenseRecordAppservice/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PayeeName: params['payeeName'],
        PayeeBankAccount: params['payeeBankAccount'],
        PayType: params['payType'],
        ContractNo: params['contractNo'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectName: params['projectName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CapitalIncomeRecordFromDonationService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromDonationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromDonationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取资金捐赠合计金额
   */
  static getDonationTotalMoney(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      donatorName?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      capitalProperty?: CapitalProperty;
      /**  */
      currencyType?: CurrencyType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetDonationTotalMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        DonatorName: params['donatorName'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        CapitalProperty: params['capitalProperty'],
        CurrencyType: params['currencyType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromDonationPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      donatorName?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      capitalProperty?: CapitalProperty;
      /**  */
      currencyType?: CurrencyType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        DonatorName: params['donatorName'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        CapitalProperty: params['capitalProperty'],
        CurrencyType: params['currencyType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      donatorName?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      capitalProperty?: CapitalProperty;
      /**  */
      currencyType?: CurrencyType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromDonationDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromDonation/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        DonatorName: params['donatorName'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        CapitalProperty: params['capitalProperty'],
        CurrencyType: params['currencyType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CapitalIncomeRecordFromOtherService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromOtherCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromOtherCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取其他收入合计金额
   */
  static getOtherTotalMoney(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      incomeCategory?: IncomeCategory;
      /**  */
      currencyType?: CurrencyType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetOtherTotalMoney';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        IncomeCategory: params['incomeCategory'],
        CurrencyType: params['currencyType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /** requestBody */
      body?: CapitalIncomeRecordFromOtherPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      incomeCategory?: IncomeCategory;
      /**  */
      currencyType?: CurrencyType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        IncomeCategory: params['incomeCategory'],
        CurrencyType: params['currencyType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      incomeDate?: string;
      /**  */
      incomeDateStart?: string;
      /**  */
      incomeDateEnd?: string;
      /**  */
      incomeType?: PayType;
      /**  */
      bankAccount?: string;
      /**  */
      totalIncomeMoney?: number;
      /**  */
      totalIncomeMoneyStart?: number;
      /**  */
      totalIncomeMoneyEnd?: number;
      /**  */
      incomeCategory?: IncomeCategory;
      /**  */
      currencyType?: CurrencyType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CapitalIncomeRecordFromOtherDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CapitalIncomeRecordFromOther/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IncomeDate: params['incomeDate'],
        IncomeDateStart: params['incomeDateStart'],
        IncomeDateEnd: params['incomeDateEnd'],
        IncomeType: params['incomeType'],
        BankAccount: params['bankAccount'],
        TotalIncomeMoney: params['totalIncomeMoney'],
        TotalIncomeMoneyStart: params['totalIncomeMoneyStart'],
        TotalIncomeMoneyEnd: params['totalIncomeMoneyEnd'],
        IncomeCategory: params['incomeCategory'],
        CurrencyType: params['currencyType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CityService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      cityName?: string;
      /**  */
      shortCode?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CityName: params['cityName'],
        ShortCode: params['shortCode'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsAnchorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsAnchorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsAnchorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /**  */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsCategoryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static showIt(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/ShowIt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hiddenIt(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/HiddenIt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取最大的排序字段
   */
  static getMaxDisplayOrder(
    params: {
      /**  */
      parentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetMaxDisplayOrder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { parentId: params['parentId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 只和UseParentIdParameter为true时使用。 */
      parentId?: number;
      /**  */
      parentCode?: string;
      /**  */
      useParentIdParameter?: boolean;
      /**  */
      isHidden?: boolean;
      /**  */
      enableDataPermissionLimit?: boolean;
      /**  */
      needShowContentCategory?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        ParentCode: params['parentCode'],
        UseParentIdParameter: params['useParentIdParameter'],
        IsHidden: params['isHidden'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NeedShowContentCategory: params['needShowContentCategory'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsContentService {
  /**
   * 获取文章详情，包含明细
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publish(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Publish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelPublish(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CancelPublish';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 置顶
   */
  static stickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/StickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消置顶
   */
  static cancelStickyPost(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/CancelStickyPost';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static moveToTrash(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/MoveToTrash';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static restoreFromTrash(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/RestoreFromTrash';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsContentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsContentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 点赞
   */
  static praise(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Praise';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量更新文章栏目
   */
  static batchChangeCategory(
    params: {
      /**  */
      contentIds?: string;
      /**  */
      categoryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/BatchChangeCategory';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { contentIds: params['contentIds'], categoryId: params['categoryId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 同步微信文章
   */
  static syncWechatNews(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/SyncWechatNews';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      title?: string;
      /**  */
      categoryId?: number;
      /**  */
      categoryCode?: string;
      /**  */
      publishYear?: number;
      /**  */
      status?: CmsContentStatus;
      /**  */
      enableDataPermissionLimit?: boolean;
      /** 指定要不包含的文章ID */
      noContainsId?: number;
      /** 排序方式.1=>默认方式,2=>lastest(最新) */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsContentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsContent/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        CategoryId: params['categoryId'],
        CategoryCode: params['categoryCode'],
        PublishYear: params['publishYear'],
        Status: params['status'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NoContainsId: params['noContainsId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsFriendLinkService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsFriendLinkCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsFriendLinkCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /**  */
      link?: string;
      /**  */
      displayOrder?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsFriendLinkDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        Link: params['link'],
        DisplayOrder: params['displayOrder'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsFriendLink/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConfigurationService {
  /**
   *
   */
  static changeUiTheme(
    params: {
      /** requestBody */
      body?: ChangeUiThemeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Configuration/ChangeUiTheme';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CustomFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static copyExistForm(
    params: {
      /** requestBody */
      body?: CustomFormCopyInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/CopyExistForm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取表单的ID
   */
  static getCustomFormId(
    params: {
      /**  */
      hostType?: CustomFormType;
      /**  */
      hostId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/GetCustomFormId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { HostType: params['hostType'], HostId: params['hostId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据类名获取默认的固定字段表单定义
   */
  static getDefaultFields(
    params: {
      /**  */
      formType?: CustomFormType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormFieldDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/GetDefaultFields';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { formType: params['formType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 拷贝
   */
  static copy(
    params: {
      /** requestBody */
      body?: CustomFormCopyInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Copy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      hostType?: CustomFormType;
      /**  */
      hostId?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        HostType: params['hostType'],
        HostId: params['hostId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DataDictionaryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DataDictionaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DataDictionaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static syncToTenant(
    params: {
      /** requestBody */
      body?: DataDictionarySyncInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/SyncToTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页查询
   */
  static getAll(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据parentID获取子字典数据
   */
  static getListByParentId(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetListByParentId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Key获取子字典数据
   */
  static getDataDictionaryListByKey(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryListByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Key获取不分页的字典项
   */
  static getDataDictionaryByKey(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的一级字典项
   */
  static getAllRoot(options: IRequestOptions = {}): Promise<DataDictionaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetAllRoot';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DataPermissionLimitService {
  /**
   * 获取数据
   */
  static getAll(
    params: {
      /**  */
      hostId?: string;
      /**  */
      hostType?: DataPermissionLimitHostType;
      /**  */
      targetType?: DataPermissionLimitTargetType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataPermissionLimitDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { HostId: params['hostId'], HostType: params['hostType'], TargetType: params['targetType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DataPermissionLimitUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reset(
    params: {
      /** requestBody */
      body?: DataPermissionLimitHostType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Reset';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DepartmentService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DepartmentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DepartmentCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DepartmentDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Department/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DownloadService {
  /**
   *
   */
  static attachment(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Download/Attachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownload(
    params: {
      /**  */
      id?: string;
      /**  */
      zipFileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Download/BatchDownload';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], zipFileName: params['zipFileName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EnumService {
  /**
   * 获取枚举选项列表
   */
  static getValues(
    params: {
      /** 枚举名称，如：NotifyTemplateType */
      typeName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetValues';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { typeName: params['typeName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllEnums(
    params: {
      /**  */
      filter?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAllEnums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filter: params['filter'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAppEnums(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAppEnums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalDueDiligenceRecordService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalDueDiligenceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 尽调形式 */
      dueDiligenceTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startDateStart?: string;
      /**  */
      startDateEnd?: string;
      /**  */
      endDateStart?: string;
      /**  */
      endDateEnd?: string;
      /**  */
      place?: string;
      /**  */
      members?: string;
      /**  */
      visitObjectJson?: string;
      /**  */
      orgInfo?: string;
      /**  */
      projectInfo?: string;
      /**  */
      omnibusOpinion?: string;
      /**  */
      organizationUnitName?: string;
      /**  */
      projectName?: string;
      /**  */
      organizationUnitId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DueDiligenceTypeId: params['dueDiligenceTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartDateStart: params['startDateStart'],
        StartDateEnd: params['startDateEnd'],
        EndDateStart: params['endDateStart'],
        EndDateEnd: params['endDateEnd'],
        Place: params['place'],
        Members: params['members'],
        VisitObjectJson: params['visitObjectJson'],
        OrgInfo: params['orgInfo'],
        ProjectInfo: params['projectInfo'],
        OmnibusOpinion: params['omnibusOpinion'],
        OrganizationUnitName: params['organizationUnitName'],
        ProjectName: params['projectName'],
        OrganizationUnitId: params['organizationUnitId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 待审核的项目如果审核中编辑了,审核状态将清0
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalDueDiligenceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 尽调形式 */
      dueDiligenceTypeId?: number;
      /** 开始时间 */
      startDate?: string;
      /** 结束时间 */
      endDate?: string;
      /**  */
      startDateStart?: string;
      /**  */
      startDateEnd?: string;
      /**  */
      endDateStart?: string;
      /**  */
      endDateEnd?: string;
      /**  */
      place?: string;
      /**  */
      members?: string;
      /**  */
      visitObjectJson?: string;
      /**  */
      orgInfo?: string;
      /**  */
      projectInfo?: string;
      /**  */
      omnibusOpinion?: string;
      /**  */
      organizationUnitName?: string;
      /**  */
      projectName?: string;
      /**  */
      organizationUnitId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DueDiligenceTypeId: params['dueDiligenceTypeId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        StartDateStart: params['startDateStart'],
        StartDateEnd: params['startDateEnd'],
        EndDateStart: params['endDateStart'],
        EndDateEnd: params['endDateEnd'],
        Place: params['place'],
        Members: params['members'],
        VisitObjectJson: params['visitObjectJson'],
        OrgInfo: params['orgInfo'],
        ProjectInfo: params['projectInfo'],
        OmnibusOpinion: params['omnibusOpinion'],
        OrganizationUnitName: params['organizationUnitName'],
        ProjectName: params['projectName'],
        OrganizationUnitId: params['organizationUnitId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDueDiligenceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalDueDiligenceRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalMonthlyPlanService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的未填写总结的月活动计划列表
   */
  static getMyExternalMonthlyPlanList(
    params: {
      /**  */
      projectId?: number;
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetMyExternalMonthlyPlanList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ProjectId: params['projectId'], Date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的项目id
   */
  static getExternalProjectIdListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetExternalProjectIdListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlyPlanDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlyPlan/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalMonthlySummarizeService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年月 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMonthlySummarizeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalMonthlySummarize/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectService {
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 复制新增
   */
  static copyCreate(
    params: {
      /** requestBody */
      body?: ExternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/CopyCreate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取外部项目计划书详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据ProjectId获取外部项目计划书详情
   */
  static getDetailByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetDetailByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailBySourceProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetDetailBySourceProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthlySummaizeDetailsByProjectId(
    params: {
      /**  */
      projectId?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthlySummaizeDetailsDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetProjectMonthlySummaizeDetailsByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        ProjectSeriesId: params['projectSeriesId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新外部项目预算表
   */
  static updateBudgetItem(
    params: {
      /** requestBody */
      body?: UpdateExternalProjectBudgetItemsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/UpdateBudgetItem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取外部项目已立项列表
   */
  static getExternalProjectList(
    params: {
      /**  */
      isIntention?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetExternalProjectList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { isIntention: params['isIntention'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的外部项目列表
   */
  static getExternalProjectListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetExternalProjectListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      orgName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      orgName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProject/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectChangeApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectChangeApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectChangeApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 变更时间 */
      changeDate?: string;
      /**  */
      changeTypes?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ChangeDate: params['changeDate'],
        ChangeTypes: params['changeTypes'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 变更时间 */
      changeDate?: string;
      /**  */
      changeTypes?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectChangeApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectChangeApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ChangeDate: params['changeDate'],
        ChangeTypes: params['changeTypes'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectCloseReportService {
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CloseReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CloseReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送评估总结报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CloseReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectCloseReport/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectIntentionService {
  /**
   * 获取项目意向书详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectIntentionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 验证数据并提交
   */
  static startAuditWithValidData(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/StartAuditWithValidData';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectIntentionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectIntentionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectIntention/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectPhaseService {
  /**
   *
   */
  static getProjectPhasesList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectPhaseItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectPhasesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectMonthList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectAttachmentListByPhase(
    params: {
      /** 项目ID */
      projectId?: number;
      /** 节点枚举 */
      stage?: WorkflowOfProjectByExternal;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectAttachmentListByPhase';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        Stage: params['stage'],
        Year: params['year'],
        Month: params['month'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectAttachmentListByMonth(
    params: {
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
      /** 项目ID */
      projectId?: number;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/GetProjectAttachmentListByMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount'],
        ProjectId: params['projectId'],
        Year: params['year'],
        Month: params['month']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownloadProjectPhasesAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      stage?: WorkflowOfProjectByExternal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/BatchDownloadProjectPhasesAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], stage: params['stage'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchDownloadProjectMonthAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectPhase/BatchDownloadProjectMonthAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], year: params['year'], month: params['month'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectProcessMonitoringService {
  /**
   * 获取监测记录详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringCreateOrUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ExternalProjectProcessMonitoringCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ExternalProjectProcessMonitoringCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送监测记录填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 监测主题 */
      title?: string;
      /** 监测方式 */
      monitoringType?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        Title: params['title'],
        MonitoringType: params['monitoringType'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /** 监测主题 */
      title?: string;
      /** 监测方式 */
      monitoringType?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalProjectProcessMonitoringDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProcessMonitoring/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        Title: params['title'],
        MonitoringType: params['monitoringType'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectProgressReportService {
  /**
   * 获取中期报告详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProgressReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProgressReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送中期报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectProgressReport/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectReportService {
  /**
   * 外部项目宏观展示
   */
  static getMacroscopic(options: IRequestOptions = {}): Promise<LineChartDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetMacroscopic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 外部项目微观展示
   */
  static getMicrocosmics(
    params: {
      /** 项目年份 */
      year?: number;
      /** 项目类型 */
      projectType?: number;
      /** 项目状态 */
      status?: WorkflowOfProjectByExternal;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalMicrocosmicDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetMicrocosmics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        ProjectType: params['projectType'],
        Status: params['status'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未完成月总结的项目
   */
  static getNotCompleteMonthlySummaryProjects(
    params: {
      /**  */
      year?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotCompleteMonthlySummaryProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未正常关项的项目
   */
  static getNotClosingNormallyProjects(
    params: {
      /**  */
      year?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotClosingNormallyProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 没有交付中期报告的项目列表
   */
  static getNotCompleteProgressReportProjects(
    params: {
      /**  */
      year?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotCompleteProgressReportProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 没有交付结项自评报告的项目列表
   */
  static getNotCompleteSummaryReportProjects(
    params: {
      /**  */
      year?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectReport/GetNotCompleteSummaryReportProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalProjectSummaryReportService {
  /**
   * 获取结项自评报告详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据项目Id获取结项自评报告详情
   */
  static getDetailByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetDetailByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送结项自评报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SummaryReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ExternalProjectSummaryReport/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FormCacheService {
  /**
   * 设置缓存
   */
  static setCache(
    params: {
      /** requestBody */
      body?: CacheFormPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FormCache/SetCache';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取缓存
   */
  static getCache(
    params: {
      /**  */
      formType?: FormType;
      /**  */
      hostId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FormCache/GetCache';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { formType: params['formType'], hostId: params['hostId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除缓存
   */
  static delCache(
    params: {
      /**  */
      formType?: FormType;
      /**  */
      hostId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FormCache/DelCache';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { formType: params['formType'], hostId: params['hostId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalMonthlyPlanService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalMonthlyPlanCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页查询
   */
  static getAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取我的月活动计划列表
   */
  static getMyInternalMonthlyPlanList(
    params: {
      /**  */
      projectId?: number;
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetMyInternalMonthlyPlanList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ProjectId: params['projectId'], Date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的项目id
   */
  static getInternalProjectIdListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetInternalProjectIdListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /** 所属项目ID */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlyPlanDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlyPlan/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalMonthlySummarizeService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalMonthlySummarizeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年 */
      date?: string;
      /** 项目名称 */
      displayName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMonthlySummarizeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalMonthlySummarize/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Date: params['date'],
        DisplayName: params['displayName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalPersonalYearEndSummaryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalPersonalYearEndSummaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalPersonalYearEndSummaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年 */
      year?: number;
      /**  */
      userName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        UserName: params['userName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 年 */
      year?: number;
      /**  */
      userName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        UserName: params['userName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalPersonalYearEndSummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalPersonalYearEndSummary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectService {
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取内部项目已立项列表
   */
  static getInternalProjectList(options: IRequestOptions = {}): Promise<InternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetInternalProjectList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据年月获取有月计划的内部项目列表
   */
  static getInternalProjectListByDate(
    params: {
      /**  */
      date?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetInternalProjectListByDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { date: params['date'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取内部项目立项书详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据项目Id获取内部项目立项书详情
   */
  static getDetailByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetDetailByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthlySummaizeDetailsByProjectId(
    params: {
      /**  */
      projectId?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthlySummaizeDetailsDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetProjectMonthlySummaizeDetailsByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        ProjectSeriesId: params['projectSeriesId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectMonthlySummaizeDetailsByProjectSeries(
    params: {
      /**  */
      projectId?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthlySummaizeDetailsDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetProjectMonthlySummaizeDetailsByProjectSeries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        ProjectSeriesId: params['projectSeriesId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProjectBudgetItemListByProjectId(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectBudgetItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetProjectBudgetItemListByProjectId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新内部项目预算表
   */
  static updateBudgetItem(
    params: {
      /** requestBody */
      body?: UpdateInternalProjectBudgetItemDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/UpdateBudgetItem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 复制内部项目
   */
  static copy(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Copy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目立项管理删除时自动删除项目邀约记录
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      displayName?: string;
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      isPhase?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        ProjectId: params['projectId'],
        Year: params['year'],
        IsPhase: params['isPhase'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProject/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectPhaseService {
  /**
   * 获取项目节点列表
   */
  static getProjectPhasesList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectPhasesItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectPhasesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目月份列表
   */
  static getProjectMonthList(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectMonthItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectMonthList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页获取项目节点附件列表，全部时Stage传null
   */
  static getProjectAttachmentListByPhase(
    params: {
      /** 项目ID */
      projectId?: number;
      /** 节点枚举 */
      stage?: WorkflowOfProjectByInternal;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectAttachmentListByPhase';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectId: params['projectId'],
        Stage: params['stage'],
        Year: params['year'],
        Month: params['month'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页获取项目月度附件列表，全部时Year和Month传0
   */
  static getProjectAttachmentListByMonth(
    params: {
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
      /** 项目ID */
      projectId?: number;
      /** 年份 */
      year?: number;
      /** 月份 */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/GetProjectAttachmentListByMonth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount'],
        ProjectId: params['projectId'],
        Year: params['year'],
        Month: params['month']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目节点附件全部下载
   */
  static batchDownloadProjectPhasesAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      stage?: WorkflowOfProjectByInternal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/BatchDownloadProjectPhasesAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], stage: params['stage'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 项目月度附件全部下载
   */
  static batchDownloadProjectMonthAttachment(
    params: {
      /**  */
      projectId?: number;
      /**  */
      year?: number;
      /**  */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectPhase/BatchDownloadProjectMonthAttachment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'], year: params['year'], month: params['month'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectReportService {
  /**
   * 内部项目宏观展示
   */
  static getMacroscopic(options: IRequestOptions = {}): Promise<LineChartDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetMacroscopic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 内部项目的微观展示
   */
  static getMicrocosmics(
    params: {
      /** 项目年份 */
      year?: number;
      /** 项目类型 */
      projectType?: number;
      /** 项目状态 */
      status?: WorkflowOfProjectByInternal;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalMicrocosmicDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetMicrocosmics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        ProjectType: params['projectType'],
        Status: params['status'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未完成月总结的项目
   */
  static getNotCompleteMonthlySummaryProjects(
    params: {
      /**  */
      year?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetNotCompleteMonthlySummaryProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取未正常关项的项目
   */
  static getNotClosingNormallyProjects(
    params: {
      /**  */
      year?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOfNotCompleteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectReport/GetNotClosingNormallyProjects';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalProjectSummaryReportsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InternalProjectSummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InternalProjectSummaryReportCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 发送总结报告填写通知
   */
  static sendNotify(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/SendNotify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 年 */
      year?: number;
      /** 项目名称 */
      displayName?: string;
      /** 填写人 */
      userName?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        DisplayName: params['displayName'],
        UserName: params['userName'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 年 */
      year?: number;
      /** 项目名称 */
      displayName?: string;
      /** 填写人 */
      userName?: string;
      /**  */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalProjectSummaryReportDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InternalProjectSummaryReports/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        DisplayName: params['displayName'],
        UserName: params['userName'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InventoryInFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InventoryInFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryInFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InventoryInFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryInFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 物资 */
      goods?: string;
      /** 品牌 */
      brand?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 是否加入存货管理 */
      isJoinInventoryManage?: boolean;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryInFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Goods: params['goods'],
        Brand: params['brand'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        IsJoinInventoryManage: params['isJoinInventoryManage'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 物资 */
      goods?: string;
      /** 品牌 */
      brand?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 是否加入存货管理 */
      isJoinInventoryManage?: boolean;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryInFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Goods: params['goods'],
        Brand: params['brand'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        IsJoinInventoryManage: params['isJoinInventoryManage'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryInFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryInForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InventoryOutFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: InventoryOutFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryOutFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: InventoryOutFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryOutFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 物资 */
      goods?: string;
      /** 品牌 */
      brand?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryOutFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Goods: params['goods'],
        Brand: params['brand'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 物资 */
      goods?: string;
      /** 品牌 */
      brand?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryOutFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Goods: params['goods'],
        Brand: params['brand'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InventoryOutFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/InventoryOutForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ItemBorrowingFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ItemBorrowingFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ItemBorrowingFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ItemBorrowingFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ItemBorrowingFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 事项 */
      description?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ItemBorrowingFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Description: params['description'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 去处理
   */
  static process(
    params: {
      /** requestBody */
      body?: ItemBorrowingFormProcessInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/Process';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 事项 */
      description?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ItemBorrowingFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Description: params['description'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ItemBorrowingFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ItemBorrowingForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MatterAuditFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: MatterAuditFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MatterAuditFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: MatterAuditFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MatterAuditFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 事项 */
      matter?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MatterAuditFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Matter: params['matter'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 事项 */
      matter?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MatterAuditFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Matter: params['matter'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MatterAuditFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/MatterAuditForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotifyRecordService {
  /**
   * 获取通知发送记录详情
   */
  static getDetail(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: NotifyRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 预览通知时机构及项目信息获取
   */
  static getNotifyProjectAndUnitInformation(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectAndOrgUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/GetNotifyProjectAndUnitInformation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: NotifyRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 通知标题 */
      title?: string;
      /** 通知类型 */
      notifyType?: NotifyTemplateType;
      /**  */
      hostId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        NotifyType: params['notifyType'],
        HostId: params['hostId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotifyTemplateService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 通知模板标题 */
      title?: string;
      /** 通知模板类型 */
      notifyTemplateType?: NotifyTemplateType;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        NotifyTemplateType: params['notifyTemplateType'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: NotifyTemplateCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: NotifyTemplateCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NotifyTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/NotifyTemplate/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationUnitService {
  /**
   * 导出合作方
   */
  static export(
    params: {
      /** requestBody */
      body?: OrganizationUnitPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 申请入驻(首次申请)
   */
  static apply(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Code获取邀约详情
   */
  static getInviteByCode(
    params: {
      /**  */
      inviteCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetInviteByCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { inviteCode: params['inviteCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重新申请
   */
  static reapply(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Reapply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: OrganizationUnitCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createLoginAccount(
    params: {
      /** requestBody */
      body?: AgileOrganizationUnit;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitLoginAccountResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/CreateLoginAccount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取志愿者申请小程序分享码
   */
  static getVolunteerApplyShareCodeImage(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetVolunteerApplyShareCodeImage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /**  */
      displayName?: string;
      /** 机构的类型(数据字典的ID） */
      organizationType?: number;
      /**  */
      isApply?: boolean;
      /**  */
      status?: OrganizationStatus;
      /**  */
      principalName?: string;
      /**  */
      mobilePhone?: string;
      /**  */
      emailAddress?: string;
      /** 联系人姓名 */
      contactName?: string;
      /** 联系人手机号 */
      contactMobilePhone?: string;
      /** 联系人邮箱 */
      contactEmailAddress?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrganizationType: params['organizationType'],
        IsApply: params['isApply'],
        Status: params['status'],
        PrincipalName: params['principalName'],
        MobilePhone: params['mobilePhone'],
        EmailAddress: params['emailAddress'],
        ContactName: params['contactName'],
        ContactMobilePhone: params['contactMobilePhone'],
        ContactEmailAddress: params['contactEmailAddress'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /** 机构的类型(数据字典的ID） */
      organizationType?: number;
      /**  */
      isApply?: boolean;
      /**  */
      status?: OrganizationStatus;
      /**  */
      principalName?: string;
      /**  */
      mobilePhone?: string;
      /**  */
      emailAddress?: string;
      /** 联系人姓名 */
      contactName?: string;
      /** 联系人手机号 */
      contactMobilePhone?: string;
      /** 联系人邮箱 */
      contactEmailAddress?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUnitDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrganizationUnit/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        OrganizationType: params['organizationType'],
        IsApply: params['isApply'],
        Status: params['status'],
        PrincipalName: params['principalName'],
        MobilePhone: params['mobilePhone'],
        EmailAddress: params['emailAddress'],
        ContactName: params['contactName'],
        ContactMobilePhone: params['contactMobilePhone'],
        ContactEmailAddress: params['contactEmailAddress'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PartnerCapitalApplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PartnerCapitalApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PartnerCapitalApplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllApprovedUnSettlementList(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 项目Id */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetAllApprovedUnSettlementList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 项目Id */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /** 项目Id */
      projectId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        ProjectId: params['projectId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PartnerCapitalApplySettlementService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PartnerCapitalApplySettlementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplySettlementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PartnerCapitalApplySettlementCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplySettlementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplySettlementDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 是否内部项目 */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplySettlementDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerCapitalApplySettlementDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerCapitalApplySettlement/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PartnerInviteService {
  /**
   *
   */
  static testEasyNetQ(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/TestEasyNetQ';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PartnerInviteCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 邀约机构名称 */
      organizationName?: string;
      /** 机构类型 */
      organizationType?: number;
      /**  */
      emailAddress?: string;
      /**  */
      mobile?: string;
      /**  */
      content?: string;
      /**  */
      hasUsed?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationName: params['organizationName'],
        OrganizationType: params['organizationType'],
        EmailAddress: params['emailAddress'],
        Mobile: params['mobile'],
        Content: params['content'],
        HasUsed: params['hasUsed'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PartnerInviteCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartnerInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PartnerInvite/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PcHomeService {
  /**
   *
   */
  static getSummary(options: IRequestOptions = {}): Promise<SummaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PCHome/GetSummary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PermissionDefinitionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PermissionDefinitionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PermissionDefinitionCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCreatedNames(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAllCreatedNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPermissionNames(options: IRequestOptions = {}): Promise<StringStringKeyValuePair[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAllPermissionNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionDefinitionDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PermissionDefinition/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PrivateCarPublicSubsidyFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: PrivateCarPublicSubsidyFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivateCarPublicSubsidyFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: PrivateCarPublicSubsidyFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivateCarPublicSubsidyFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivateCarPublicSubsidyFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 去处理
   */
  static process(
    params: {
      /** requestBody */
      body?: PrivateCarPublicSubsidyFormProcessInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/Process';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivateCarPublicSubsidyFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivateCarPublicSubsidyFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/PrivateCarPublicSubsidyForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      year?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      projectTypes?: string;
      /**  */
      sorting?: string;
      /**  */
      noContainsId?: number;
      /** 是否忽略IsPublicShow查询条件的判断 */
      isIgnorePublicShow?: boolean;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Year: params['year'],
        ProjectSeriesId: params['projectSeriesId'],
        ProjectTypes: params['projectTypes'],
        Sorting: params['sorting'],
        NoContainsId: params['noContainsId'],
        IsIgnorePublicShow: params['isIgnorePublicShow'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目基本信息详情
   */
  static getProjectBasicDetail(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBasicDetailUpdateDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/GetProjectBasicDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新项目基本信息
   */
  static updateProjectBasicDetail(
    params: {
      /** requestBody */
      body?: ProjectBasicDetailUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/UpdateProjectBasicDetail';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置是否公开显示
   */
  static changePublicShow(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/ChangePublicShow';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 终止项目
   */
  static terminate(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Terminate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取项目合作机构Id
   */
  static getProjectMappings(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectOrganizationUnitMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/GetProjectMappings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新项目合作机构
   */
  static updateMapping(
    params: {
      /** requestBody */
      body?: ProjectOrganizationUnitMappingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/UpdateMapping';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectFullInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Project/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectBeneficiaryMappingService {
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      beneficiaryName?: string;
      /**  */
      projectId?: number;
      /**  */
      isCreateServiceRecord?: boolean;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BeneficiaryName: params['beneficiaryName'],
        ProjectId: params['projectId'],
        IsCreateServiceRecord: params['isCreateServiceRecord'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAll(
    params: {
      /**  */
      beneficiaryName?: string;
      /**  */
      projectId?: number;
      /**  */
      isCreateServiceRecord?: boolean;
      /** 性别 */
      sex?: string;
      /** 最小年龄 */
      minAge?: number;
      /** 最大年龄 */
      maxAge?: number;
      /** 民族 */
      nation?: string;
      /** 省份Id */
      provinceId?: number;
      /** 城市Id */
      cityId?: number;
      /** 区域Id */
      areaId?: number;
      /** 街道Id */
      townId?: number;
      /** 是否享受低保政策 */
      isEnjoyTheSubsistenceAllowancePolicy?: boolean;
      /** 是否为建档立卡户 */
      isEnjoyOtherSalvation?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        BeneficiaryName: params['beneficiaryName'],
        ProjectId: params['projectId'],
        IsCreateServiceRecord: params['isCreateServiceRecord'],
        Sex: params['sex'],
        MinAge: params['minAge'],
        MaxAge: params['maxAge'],
        Nation: params['nation'],
        ProvinceId: params['provinceId'],
        CityId: params['cityId'],
        AreaId: params['areaId'],
        TownId: params['townId'],
        IsEnjoyTheSubsistenceAllowancePolicy: params['isEnjoyTheSubsistenceAllowancePolicy'],
        IsEnjoyOtherSalvation: params['isEnjoyOtherSalvation'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectBeneficiaryMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectBeneficiaryMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectBeneficiaryMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 受益人批量分配机构
   */
  static batchDistributionOrg(
    params: {
      /** requestBody */
      body?: UserOrganizationUnitInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/BatchDistributionOrg';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static import(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(
    params: {
      /**  */
      projectId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectId: params['projectId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectBeneficiaryMapping/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectInviteService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectInviteCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectInviteCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      isInternal?: boolean;
      /** 项目名称 */
      displayName?: string;
      /** 机构名称 */
      orgName?: string;
      /** 年份 */
      year?: number;
      /**  */
      projectSeriesId?: number;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectInviteDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectInvite/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsInternal: params['isInternal'],
        DisplayName: params['displayName'],
        OrgName: params['orgName'],
        Year: params['year'],
        ProjectSeriesId: params['projectSeriesId'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectSeriesService {
  /**
   * 根据Sequence升序获取列表
   */
  static getProjectSeriesList(options: IRequestOptions = {}): Promise<ProjectSeries[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/GetProjectSeriesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParentProjectSeriesList(options: IRequestOptions = {}): Promise<ProjectSeries[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/GetParentProjectSeriesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSubProjectSeriesList(
    params: {
      /**  */
      parentId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeries[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/GetSubProjectSeriesList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { parentId: params['parentId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 系列名称 */
      title?: string;
      /** 是否公开显示 */
      isPublicShow?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        IsPublicShow: params['isPublicShow'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProjectSeriesCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProjectSeriesCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSeriesDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProjectSeries/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProvinceService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProvinceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProvinceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(options: IRequestOptions = {}): Promise<ProvinceDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      provinceName?: string;
      /**  */
      shortName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProvinceName: params['provinceName'],
        ShortName: params['shortName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoleService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: RoleCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoles(
    params: {
      /**  */
      permission?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleListDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Permission: params['permission'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: RoleCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有可分配的权限信息
   */
  static getAllPermissions(options: IRequestOptions = {}): Promise<PermissionDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetAllPermissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取已分配的权限信息
   */
  static getGrantedPermissionNames(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetGrantedPermissionNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetRoleForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetRoleForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SessionService {
  /**
   *
   */
  static getCurrentLoginInformations(options: IRequestOptions = {}): Promise<GetCurrentLoginInformationsOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Session/GetCurrentLoginInformations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SwiperService {
  /**
   * 根据锚点编号获取轮播图列表
   */
  static getSwiperListByAnchor(
    params: {
      /**  */
      anchorName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Swiper[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/GetSwiperListByAnchor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { anchorName: params['anchorName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      cmsAnchorId?: number;
      /**  */
      anchorName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        CmsAnchorId: params['cmsAnchorId'],
        AnchorName: params['anchorName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SwiperCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SwiperCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateTenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TestEntityService {
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TestMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TestMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TestMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TestEntity/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TokenAuthService {
  /**
   *
   */
  static authenticate(
    params: {
      /** requestBody */
      body?: AuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/Authenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExternalAuthenticationProviders(options: IRequestOptions = {}): Promise<ExternalLoginProviderInfoModel[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/GetExternalAuthenticationProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalAuthenticate(
    params: {
      /** requestBody */
      body?: ExternalAuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalAuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/ExternalAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static weChatAppletAuthenticate(
    params: {
      /** requestBody */
      body?: WeChatAppletAuthenticate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalAuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/WeChatAppletAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TownService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TownCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TownCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      areaId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { areaId: params['areaId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      townName?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TownName: params['townName'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      title?: string;
      /**  */
      status?: CmsContentStatus;
      /**  */
      createdByMe?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Status: params['status'],
        CreatedByMe: params['createdByMe'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Train/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainRecordService {
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      title?: string;
      /**  */
      name?: string;
      /**  */
      phone?: string;
      /**  */
      trainId?: number;
      /**  */
      createdByMe?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Name: params['name'],
        Phone: params['phone'],
        TrainId: params['trainId'],
        CreatedByMe: params['createdByMe'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 报名培训
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消报名培训
   */
  static cancel(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Cancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据登录用户获取用户是否报名 该培训
   */
  static getDetailByPortal(
    params: {
      /** 培训ID */
      trainId?: number;
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/GetDetailByPortal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { TrainId: params['trainId'], Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TrainVideoLearnRecordService {
  /**
   * 开始学习
   */
  static startLearn(
    params: {
      /** requestBody */
      body?: TrainVideoLearnInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/StartLearn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 学习暂停
   */
  static updateLearnCloseTime(
    params: {
      /** requestBody */
      body?: TrainVideoLearnRecordInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/UpdateLearnCloseTime';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 学习结束
   */
  static updateFinish(
    params: {
      /** requestBody */
      body?: TrainVideoLearnRecordInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/UpdateFinish';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainVideoLearnRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      trainRecordId?: number;
      /**  */
      createdByMe?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainVideoLearnRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TrainRecordId: params['trainRecordId'],
        CreatedByMe: params['createdByMe'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TrainVideoLearnRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainVideoLearnRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TrainVideoLearnRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrainVideoLearnRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TrainVideoLearnRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TravelApprovalFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TravelApprovalFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TravelApprovalFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TravelApprovalFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TravelApprovalFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 出行时间 */
      travelTime?: string;
      /** 目的地 */
      destination?: string;
      /** 出行人员 */
      travelers?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TravelApprovalFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        TravelTime: params['travelTime'],
        Destination: params['destination'],
        Travelers: params['travelers'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 项目名称 */
      projectName?: string;
      /** 出行时间 */
      travelTime?: string;
      /** 目的地 */
      destination?: string;
      /** 出行人员 */
      travelers?: string;
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TravelApprovalFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ProjectName: params['projectName'],
        TravelTime: params['travelTime'],
        Destination: params['destination'],
        Travelers: params['travelers'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TravelApprovalFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/TravelApprovalForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UEditorHandlerService {
  /**
   *
   */
  static uEditorUpload(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UEditorHandler/UEditorUpload';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uEditorUpload1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UEditorHandler/UEditorUpload';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uEditorUpload2(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/UEditorHandler/UEditorUpload';

      const configs: IRequestConfig = getConfigs('options', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoles(options: IRequestOptions = {}): Promise<RoleDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeLanguage(
    params: {
      /** requestBody */
      body?: ChangeUserLanguageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangeLanguage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      organizationUnitId?: number;
      /**  */
      departmentId?: number;
      /**  */
      departments?: string;
      /** 用户查询模式: foundation | organizationUnit */
      mode?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        OrganizationUnitId: params['organizationUnitId'],
        DepartmentId: params['departmentId'],
        Departments: params['departments'],
        Mode: params['mode'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前租户下的所有用户
   */
  static getAllUsers(options: IRequestOptions = {}): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetAllUsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取机构的所有用户
   */
  static getOrganizationUnitUsers(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      organizationUnitId?: number;
      /**  */
      departmentId?: number;
      /**  */
      departments?: string;
      /** 用户查询模式: foundation | organizationUnit */
      mode?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizationUnitDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetOrganizationUnitUsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        OrganizationUnitId: params['organizationUnitId'],
        DepartmentId: params['departmentId'],
        Departments: params['departments'],
        Mode: params['mode'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据角色编号获取用户
   */
  static getUserListByRoleName(
    params: {
      /**  */
      roleName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetUserListByRoleName';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { roleName: params['roleName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VehicleUsageApplicationFormService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VehicleUsageApplicationFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleUsageApplicationFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VehicleUsageApplicationFormCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleUsageApplicationFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleUsageApplicationFormDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createAuditQuery(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IAuditQueryBase> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/CreateAuditQuery';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 去处理
   */
  static process(
    params: {
      /** requestBody */
      body?: VehicleUsageApplicationFormProcessInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/Process';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 提交审核
   */
  static startAudit(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/StartAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: AuditUserLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取得当前登录用户能审核的所有机构的项目
   */
  static getMyAll(
    params: {
      /** 开始时间 */
      startDate?: string;
      /** 截止时间 */
      endDate?: string;
      /**  */
      createdByMe?: boolean;
      /**  */
      isApproved?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleUsageApplicationFormDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/GetMyAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        CreatedByMe: params['createdByMe'],
        isApproved: params['isApproved'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前记录的所有审批日志
   */
  static getLogs(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditUserLogDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/GetLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStatus(
    params: {
      /**  */
      audit?: number;
      /**  */
      auditStatus?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/GetStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { audit: params['audit'], auditStatus: params['auditStatus'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleUsageApplicationFormDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VehicleUsageApplicationForm/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VolunteerService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: VolunteerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: VolunteerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 审核
   */
  static audit(
    params: {
      /** requestBody */
      body?: VolunteerAuditInputDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Audit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取登录志愿者 信息
   */
  static getCurrentUserVolunteerStatus(options: IRequestOptions = {}): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetCurrentUserVolunteerStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      name?: string;
      /**  */
      phoneNumber?: string;
      /** 邀约合作机构Id */
      organizationUnitId?: number;
      /**  */
      status?: VolunteerAuditStatus;
      /**  */
      createdByMe?: boolean;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        PhoneNumber: params['phoneNumber'],
        OrganizationUnitId: params['organizationUnitId'],
        Status: params['status'],
        CreatedByMe: params['createdByMe'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Volunteer/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface IsTenantAvailableInput {
  /**  */
  tenancyName?: string;
}

export interface IsTenantAvailableOutput {
  /**  */
  state?: TenantAvailabilityState;

  /**  */
  tenantId?: number;
}

export interface RegisterInput {
  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  userName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  password?: string;

  /**  */
  captchaResponse?: string;
}

export interface RegisterOutput {
  /**  */
  canLogin?: boolean;
}

export interface LoginPostReqDto {
  /** 用户名 */
  username?: string;

  /** 密码 */
  password?: string;

  /** 是否记住我 */
  rememberMe?: boolean;

  /** 租户 */
  tenancyName?: string;
}

export interface LoginPostRespDto {
  /**  */
  errorInfo?: string;

  /**  */
  accessToken?: string;

  /**  */
  identityToken?: string;

  /**  */
  expiresIn?: number;

  /** 租户Id */
  tenantId?: number;

  /** 机构Id */
  organizationUnitId?: number;

  /** 用户Id */
  userId?: number;

  /** 企业微信Id */
  workWeiXinId?: string;
}

export interface AlbumDto {
  /** 相册名称 */
  name?: string;

  /** 相册描述 */
  summary?: string;

  /** 封面地址 */
  cover?: string;

  /**  */
  tenantId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface AlbumCreateUpdateDto {
  /** 相册名称 */
  name?: string;

  /** 相册描述 */
  summary?: string;

  /** 封面地址 */
  cover?: string;

  /**  */
  id?: number;
}

export interface AlbumDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AlbumDto[];
}

export interface AlbumPhotoDto {
  /**  */
  id?: number;

  /** 照片名称 */
  name?: string;

  /** 照片大小(byte) */
  photoSize?: number;

  /**  */
  size?: string;

  /** 照片路径 */
  photoPath?: string;
}

export interface AlbumPhotoDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AlbumPhotoDto[];
}

export interface AlbumPhotoMoveInput {
  /** 目标相册 */
  albumId?: number;

  /**  */
  id?: number;
}

export interface RenameAlbumPhotoInput {
  /** 照片名称 */
  name?: string;

  /**  */
  id?: number;
}

export interface AreaCreateOrUpdateDto {
  /**  */
  cityId?: number;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface AreaDto {
  /**  */
  cityId?: number;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface AreaDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AreaDto[];
}

export interface AttachmentDto {
  /**  */
  fileName?: string;

  /** 文件的扩展名 */
  extension?: string;

  /** 是否为临时文件 */
  isTemp?: boolean;

  /**  */
  url?: string;

  /** 附件的宿主ID */
  hostId?: string;

  /**  */
  hostType?: AttachmentHostType;

  /** 文件的长度大小(单位：字节） */
  contentLength?: number;

  /** 文件的大小(自动计算的属性) */
  fileSize?: string;

  /**  */
  id?: number;
}

export interface Credentials {
  /**  */
  accessKeyId?: string;

  /**  */
  accessKeySecret?: string;

  /**  */
  expiration?: string;

  /**  */
  securityToken?: string;
}

export interface AssumedRoleUser {
  /**  */
  arn?: string;

  /**  */
  assumedRoleId?: string;
}

export interface AliyunOssStsDto {
  /**  */
  requestId?: string;

  /**  */
  region?: string;

  /**  */
  bucket?: string;

  /**  */
  endpoint?: string;

  /**  */
  fileUrl?: string;

  /**  */
  imageUrl?: string;

  /**  */
  credentials?: Credentials;

  /**  */
  assumedRoleUser?: AssumedRoleUser;
}

export interface AttachmentCreateOrUpdateDto {
  /**  */
  fileName?: string;

  /**  */
  url?: string;

  /** 附件的宿主ID */
  hostId?: string;

  /**  */
  hostType?: AttachmentHostType;

  /** 文件的长度大小(单位：字节） */
  contentLength?: number;

  /** 文件的大小(自动计算的属性) */
  fileSize?: string;

  /**  */
  contentType?: string;

  /**  */
  id?: number;
}

export interface UploadResult {
  /**  */
  hostType?: AttachmentHostType;

  /**  */
  attachmentId?: number;

  /**  */
  fileName?: string;

  /**  */
  url?: string;
}

export interface AuditNodeDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  tenantId?: number;

  /**  */
  iCanAudit?: boolean;

  /**  */
  id?: string;
}

export interface AuditFlowDto {
  /** 标题 */
  title?: string;

  /** 描述 */
  desc?: string;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  scope?: AuditFlowScope;

  /**  */
  type?: AuditFlowType;

  /**  */
  nodesMaxIndex?: number;

  /** 节点 */
  auditNodes?: AuditNodeDto[];

  /**  */
  id?: string;
}

export interface AuditNodeCreateOrEditDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  tenantId?: number;

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditDto {
  /** 标题 */
  title?: string;

  /** 描述 */
  desc?: string;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  type?: AuditFlowType;

  /**  */
  scope?: AuditFlowScope;

  /** 节点 */
  auditNodes?: AuditNodeCreateOrEditDto[];

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditDtoGetForEditOutput {
  /**  */
  data?: AuditFlowCreateOrEditDto;

  /**  */
  schema?: any | null;
}

export interface GuidEntityDto {
  /**  */
  id?: string;
}

export interface CanAuditRequestDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;
}

export interface AuditFlowDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AuditFlowDto[];
}

export interface AuditNodePreDefineCreateOrEditDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineCreateOrEditDtoGetForEditOutput {
  /**  */
  data?: AuditNodePreDefineCreateOrEditDto;

  /**  */
  schema?: any | null;
}

export interface UserMiniDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;
}

export interface RoleMiniDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  id?: number;
}

export interface AuditNodePreDefineDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  userMini?: UserMiniDto;

  /**  */
  role?: RoleMiniDto;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AuditNodePreDefineDto[];
}

export interface DecimalMonthlyItem {
  /**  */
  date?: string;

  /**  */
  value?: number;
}

export interface AreaSelectDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  townId?: number;
}

export interface FamilyMembersInfoDto {
  /** 姓名 */
  name?: string;

  /** 称谓 */
  appellation?: string;

  /** 出生年月 */
  birthDate?: Date;

  /** 健康情况 */
  health?: string;

  /** 职业 */
  occupation?: string;

  /** 年收入 */
  annualIncome?: number;

  /**  */
  id?: string;
}

export interface OrganizationUnitShortDto {
  /**  */
  displayName?: string;

  /**  */
  id?: number;
}

export interface BeneficiaryDto {
  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /**  */
  nationText?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  id?: number;
}

export interface BeneficiaryCreateOrUpdateDto {
  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface UserToken {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  loginProvider?: string;

  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  expireDate?: Date;

  /**  */
  id?: number;
}

export interface UserLogin {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  loginProvider?: string;

  /**  */
  providerKey?: string;

  /**  */
  id?: number;
}

export interface UserRole {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  roleId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface UserClaim {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  claimType?: string;

  /**  */
  claimValue?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface UserPermissionSetting {
  /**  */
  userId?: number;

  /**  */
  tenantId?: number;

  /**  */
  name?: string;

  /**  */
  isGranted?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Setting {
  /**  */
  tenantId?: number;

  /**  */
  userId?: number;

  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface User {
  /**  */
  departmentId?: number;

  /**  */
  normalizedUserName?: string;

  /**  */
  normalizedEmailAddress?: string;

  /**  */
  concurrencyStamp?: string;

  /**  */
  tokens?: UserToken[];

  /**  */
  deleterUser?: User;

  /**  */
  creatorUser?: User;

  /**  */
  lastModifierUser?: User;

  /**  */
  authenticationSource?: string;

  /**  */
  userName?: string;

  /**  */
  tenantId?: number;

  /**  */
  emailAddress?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  fullName?: string;

  /**  */
  password?: string;

  /**  */
  emailConfirmationCode?: string;

  /**  */
  passwordResetCode?: string;

  /**  */
  lockoutEndDateUtc?: Date;

  /**  */
  accessFailedCount?: number;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  isPhoneNumberConfirmed?: boolean;

  /**  */
  securityStamp?: string;

  /**  */
  isTwoFactorEnabled?: boolean;

  /**  */
  logins?: UserLogin[];

  /**  */
  roles?: UserRole[];

  /**  */
  claims?: UserClaim[];

  /**  */
  permissions?: UserPermissionSetting[];

  /**  */
  settings?: Setting[];

  /**  */
  isEmailConfirmed?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface AuditUserLog {
  /**  */
  hostId?: string;

  /**  */
  hostType?: AuditFlowScope;

  /**  */
  auditFlowId?: string;

  /**  */
  auditNodeId?: string;

  /**  */
  status?: AuditUserLogStatus;

  /**  */
  desc?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  creatorUser?: User;

  /**  */
  tenantId?: number;

  /**  */
  auditStatus?: number;

  /**  */
  expired?: boolean;

  /**  */
  id?: number;
}

export interface AuditNode {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  auditFlow?: AuditFlow;

  /**  */
  tenantId?: number;

  /**  */
  id?: string;
}

export interface AuditFlow {
  /**  */
  title?: string;

  /**  */
  desc?: string;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  scope?: AuditFlowScope;

  /**  */
  type?: AuditFlowType;

  /**  */
  auditNodes?: AuditNode[];

  /**  */
  extensionData?: string;

  /**  */
  tenantId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface IAuditQueryBase {
  /**  */
  input?: AuditUserLog;

  /**  */
  flow?: AuditFlow;

  /**  */
  node?: AuditNode;
}

export interface Int32EntityDto {
  /**  */
  id?: number;
}

export interface BeneficiaryDtoListResultDto {
  /**  */
  items?: BeneficiaryDto[];
}

export interface AuditUserLogDto {
  /**  */
  hostType?: AuditUserLogType;

  /**  */
  status?: AuditUserLogStatus;

  /**  */
  desc?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  expired?: boolean;

  /**  */
  auditNodeId?: string;

  /**  */
  id?: number;
}

export interface BeneficiaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: BeneficiaryDto[];
}

export interface ProjectDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  displayName?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  titleImagePath?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /**  */
  year?: number;

  /**  */
  workflowStep?: WorkflowOfProjectByExternal;

  /**  */
  id?: number;
}

export interface ProvinceDto {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface CityDto {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface TownDto {
  /**  */
  areaId?: number;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface OrganizationUnitDto {
  /** 机构logo */
  logoImageUrl?: string;

  /** 机构名称 */
  displayName?: string;

  /** 机构类型,数据字典Id */
  organizationType?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 地点 */
  place?: string;

  /** 负责人姓名 */
  principalName?: string;

  /** 主要负责人的手机号 */
  mobilePhone?: string;

  /** 主要负责人的邮箱 */
  emailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 合作方银行账号名称 */
  bankAccountName?: string;

  /** 开户行信息 */
  bankName?: string;

  /** 开户账号 */
  bankAccount?: string;

  /** 收件人 */
  receiverName?: string;

  /** 收件地址 */
  receiverAddress?: string;

  /** 收件电话 */
  receiverMobile?: string;

  /** 法人登记证书复印件 */
  certificateImagePath?: string;

  /**  */
  extensionData?: string;

  /**  */
  registerTime?: Date;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProjectServiceItemDto {
  /**  */
  sequence?: number;

  /**  */
  serviceTime?: Date;

  /**  */
  serviceMethod?: string;

  /**  */
  serviceContent?: string;

  /**  */
  target?: string;

  /**  */
  completeStatus?: ProjectServiceRecordCompleteStatus;

  /**  */
  remark?: string;

  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  serviceMethods?: string[];

  /**  */
  id?: number;
}

export interface ServiceRecordDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  serviceRecordType?: ServiceRecordType;

  /**  */
  beneficiaryId?: number;

  /**  */
  beneficiary?: BeneficiaryDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  schoolId?: number;

  /**  */
  school?: OrganizationUnitDto;

  /**  */
  extensionData?: string;

  /**  */
  projectBeneficiaryMappingId?: number;

  /**  */
  beneficiaryOrganizationNames?: string;

  /**  */
  simpleDetail?: ProjectServiceItemDto;

  /**  */
  serviceItems?: ProjectServiceItemDto[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creationTime?: Date;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface ServiceRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ServiceRecordDto[];
}

export interface ProjectServiceItemCreateOrUpdateDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  tenantId?: number;

  /**  */
  serviceTime?: Date;

  /**  */
  serviceMethod?: string;

  /**  */
  serviceMethods?: string[];

  /**  */
  serviceContent?: string;

  /**  */
  target?: string;

  /**  */
  completeStatus?: ProjectServiceRecordCompleteStatus;

  /**  */
  remark?: string;

  /**  */
  savedAttachments?: number[];
}

export interface ServiceRecordCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /**  */
  serviceRecordType?: ServiceRecordType;

  /**  */
  beneficiaryId?: number;

  /**  */
  schoolId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  extensionData?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  projectBeneficiaryMappingId?: number;

  /**  */
  serviceItems?: ProjectServiceItemCreateOrUpdateDto[];

  /**  */
  extensionObject?: object;

  /**  */
  formId?: number;

  /**  */
  id?: number;
}

export interface ServiceRecordPagedResultRequestDto {
  /**  */
  serviceRecordType?: ServiceRecordType;

  /** 受益人名称 */
  beneficiaryName?: string;

  /** 学校名称 */
  schoolName?: string;

  /** 项目名称 */
  projectName?: string;

  /** 项目Id */
  projectId?: number;

  /** 受益人Id */
  beneficiaryId?: number;

  /** 机构Id */
  organizationUnitId?: number;

  /**  */
  sorting?: string;

  /**  */
  skipCount?: number;

  /**  */
  maxResultCount?: number;
}

export interface ServiceRecordDtoListResultDto {
  /**  */
  items?: ServiceRecordDto[];
}

export interface CapitalExpenseRecordDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 项目类型 */
  projectType?: string;

  /** 项目类型名称 */
  projectTypeName?: string;

  /** 项目Id（-1为待分摊） */
  projectId?: number;

  /** 内部项目预算子项目内容 */
  internalProjectBudgetItemId?: string;

  /** 三级科目 */
  itemName?: string;

  /** 摘要 */
  summary?: string;

  /** 本次支付金额（元） */
  money?: number;

  /** 备注 */
  remark?: string;

  /** 是否超出预算(该项目该三级科目已支付金额加本次支付金额是否超出预算金额) */
  isOver?: boolean;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordCreateOrUpdateDto {
  /** 收款单位 */
  payeeName?: string;

  /** 开户银行 */
  payeeBankName?: string;

  /** 开户账号 */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /** 付款账号（数据字典，多选一） */
  payBankAccount?: string;

  /** 合同编号 */
  contractNo?: string;

  /** 合同总金额（元） */
  contractTotalMoney?: number;

  /** 累计已支付金额（元） */
  paidTotalMoney?: number;

  /** 本次支付合计金额（元） */
  totalMoney?: number;

  /** 剩余待分摊总金额（元） */
  nonApportionTotalMoney?: number;

  /**  */
  details?: CapitalExpenseRecordDetailCreateOrUpdateDto[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProjectFullInfoDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  displayName?: string;

  /**  */
  year?: number;

  /**  */
  description?: string;

  /**  */
  projectTypes?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  projectSeriesId?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  isInternal?: boolean;

  /**  */
  progressReportDate?: Date;

  /**  */
  summaryReportDate?: Date;

  /**  */
  workflowStep?: number;

  /**  */
  projectResult?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /**  */
  id?: number;
}

export interface CapitalExpenseRecordDetailDto {
  /**  */
  sequence?: number;

  /**  */
  capitalExpenseRecordId?: number;

  /** 项目类型 */
  projectType?: string;

  /** 项目类型名称 */
  projectTypeName?: string;

  /** 项目Id（-1为待分摊） */
  projectId?: number;

  /**  */
  project?: ProjectFullInfoDto;

  /** 内部项目预算子项目内容 */
  internalProjectBudgetItemId?: string;

  /** 三级科目 */
  itemName?: string;

  /** 摘要 */
  summary?: string;

  /** 本次支付金额（元） */
  money?: number;

  /** 备注 */
  remark?: string;

  /** 是否超出预算(该项目该三级科目已支付金额加本次支付金额是否超出预算金额) */
  isOver?: boolean;

  /**  */
  totalAmount?: number;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordDto {
  /** 收款单位 */
  payeeName?: string;

  /** 开户银行 */
  payeeBankName?: string;

  /** 开户账号 */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /** 付款账号（数据字典，多选一） */
  payBankAccount?: string;

  /** 合同编号 */
  contractNo?: string;

  /** 合同总金额（元） */
  contractTotalMoney?: number;

  /** 累计已支付金额（元） */
  paidTotalMoney?: number;

  /** 本次支付合计金额（元） */
  totalMoney?: number;

  /** 剩余待分摊总金额（元） */
  nonApportionTotalMoney?: number;

  /**  */
  details?: CapitalExpenseRecordDetailDto[];

  /**  */
  payDate?: Date;

  /**  */
  simpleDetail?: CapitalExpenseRecordDetailDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  version?: number;

  /**  */
  preVersionId?: number;

  /**  */
  changeState?: ChangeState;

  /**  */
  id?: number;
}

export interface ContractNoInformationDto {
  /**  */
  contractTotalMoney?: number;

  /**  */
  paidTotalMoney?: number;
}

export interface InternalBudgetItemAndUseRecordDto {
  /**  */
  projectId?: number;

  /** 序列(显示顺序） */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /**  */
  actualMoney?: number;

  /**  */
  id?: string;
}

export interface ExcelRowImportError {
  /**  */
  errorMessage?: string;

  /**  */
  details?: string[];
}

export interface ExcelRowImportResult {
  /**  */
  model?: object;

  /**  */
  success?: boolean;

  /**  */
  error?: ExcelRowImportError;

  /**  */
  rowNumber?: number;

  /**  */
  resultType?: ImportResultType;
}

export interface ImportResult {
  /**  */
  excelFileName?: string;

  /**  */
  importResults?: ExcelRowImportResult[];
}

export interface CapitalExpenseRecordPagedResultRequestDto {
  /** 收款单位 */
  payeeName?: string;

  /** 开户银行 */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /** 合同编号 */
  contractNo?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 截止日期 */
  endDate?: Date;

  /**  */
  projectName?: string;

  /**  */
  sorting?: string;

  /**  */
  skipCount?: number;

  /**  */
  maxResultCount?: number;
}

export interface ProjectReportDto {
  /**  */
  totalAmount?: number;

  /**  */
  projectName?: string;

  /**  */
  year?: number;

  /**  */
  projectSeries?: string;

  /**  */
  projectType?: string;

  /**  */
  projectTypeName?: string;

  /**  */
  projectId?: number;
}

export interface ProjectReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectReportDto[];
}

export interface ProjectDetailReprotDto {
  /**  */
  money?: number;

  /**  */
  itemName?: string;

  /**  */
  remark?: string;

  /**  */
  summary?: string;

  /**  */
  payeeName?: string;

  /**  */
  payeeBankName?: string;

  /**  */
  payeeBankAccount?: string;

  /**  */
  contractNo?: string;

  /**  */
  payDate?: Date;

  /**  */
  totalAmount?: number;

  /**  */
  projectName?: string;

  /**  */
  year?: number;

  /**  */
  projectSeries?: string;

  /**  */
  projectType?: string;

  /**  */
  projectTypeName?: string;

  /**  */
  projectId?: number;
}

export interface ProjectDetailReprotDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectDetailReprotDto[];
}

export interface CapitalExpenseRecord {
  /**  */
  tenantId?: number;

  /**  */
  payeeName?: string;

  /**  */
  payeeBankName?: string;

  /**  */
  payeeBankAccount?: string;

  /**  */
  payType?: PayType;

  /**  */
  payBankAccount?: string;

  /**  */
  contractNo?: string;

  /**  */
  contractTotalMoney?: number;

  /**  */
  paidTotalMoney?: number;

  /**  */
  payDate?: Date;

  /**  */
  totalMoney?: number;

  /**  */
  nonApportionTotalMoney?: number;

  /**  */
  details?: CapitalExpenseRecordDetail[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  enableRestartAudit?: boolean;

  /**  */
  creatorUser?: User;

  /**  */
  version?: number;

  /**  */
  preVersionId?: number;

  /**  */
  changeState?: ChangeState;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProjectSeries {
  /**  */
  tenantId?: number;

  /**  */
  title?: string;

  /**  */
  titleImagePath?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  sequence?: number;

  /**  */
  isDefault?: boolean;

  /**  */
  ignored?: boolean;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  seriesType?: ProjectSeriesType;

  /**  */
  achievement?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Province {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface City {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: Province;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface Area {
  /**  */
  cityId?: number;

  /**  */
  city?: City;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface Town {
  /**  */
  areaId?: number;

  /**  */
  area?: Area;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface OrganizationUnit {
  /**  */
  tenantId?: number;

  /**  */
  parent?: OrganizationUnit;

  /**  */
  parentId?: number;

  /**  */
  code?: string;

  /**  */
  displayName?: string;

  /**  */
  children?: OrganizationUnit[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface AgileOrganizationUnit {
  /**  */
  organizationType?: number;

  /**  */
  logoImageUrl?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: Province;

  /**  */
  cityId?: number;

  /**  */
  city?: City;

  /**  */
  areaId?: number;

  /**  */
  area?: Area;

  /**  */
  townId?: number;

  /**  */
  town?: Town;

  /**  */
  place?: string;

  /**  */
  principalName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /**  */
  contactName?: string;

  /**  */
  contactMobilePhone?: string;

  /**  */
  contactEmailAddress?: string;

  /**  */
  creditCode?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  bankName?: string;

  /**  */
  bankAccount?: string;

  /**  */
  receiverName?: string;

  /**  */
  receiverAddress?: string;

  /**  */
  receiverMobile?: string;

  /**  */
  certificateImagePath?: string;

  /**  */
  extensionData?: string;

  /**  */
  registerTime?: Date;

  /**  */
  extensionObject?: object;

  /**  */
  creatorUser?: User;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  enableRestartAudit?: boolean;

  /**  */
  partnerInviteId?: number;

  /**  */
  tenantId?: number;

  /**  */
  parent?: OrganizationUnit;

  /**  */
  parentId?: number;

  /**  */
  code?: string;

  /**  */
  displayName?: string;

  /**  */
  children?: OrganizationUnit[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Project {
  /**  */
  displayName?: string;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  year?: number;

  /**  */
  description?: string;

  /**  */
  projectTypes?: string;

  /**  */
  titleImagePath?: string;

  /**  */
  isPublicShow?: boolean;

  /**  */
  projectSeriesId?: number;

  /**  */
  projectSeries?: ProjectSeries;

  /**  */
  isInternal?: boolean;

  /**  */
  workflowStep?: number;

  /**  */
  tenantId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: AgileOrganizationUnit;

  /**  */
  progressReportDate?: Date;

  /**  */
  summaryReportDate?: Date;

  /**  */
  ignored?: boolean;

  /**  */
  projectResult?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CapitalExpenseRecordDetail {
  /**  */
  capitalExpenseRecordId?: number;

  /**  */
  capitalExpenseRecord?: CapitalExpenseRecord;

  /**  */
  sequence?: number;

  /**  */
  projectType?: string;

  /**  */
  projectTypeName?: string;

  /**  */
  projectId?: number;

  /**  */
  project?: Project;

  /**  */
  internalProjectBudgetItemId?: string;

  /**  */
  itemName?: string;

  /**  */
  summary?: string;

  /**  */
  money?: number;

  /**  */
  remark?: string;

  /**  */
  isOver?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface CapitalExpenseRecordDtoListResultDto {
  /**  */
  items?: CapitalExpenseRecordDto[];
}

export interface CapitalExpenseRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CapitalExpenseRecordDto[];
}

export interface CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto {
  /**  */
  id?: string;

  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /** 项目Id */
  projectId?: number;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 备注 */
  remark?: string;
}

export interface CapitalIncomeRecordFromDonationCreateOrUpdateDto {
  /** 捐赠时间 */
  incomeDate?: Date;

  /** 捐赠方名称 */
  donatorName?: string;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  details?: CapitalIncomeRecordFromDonationDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromDonationDetailDto {
  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /** 项目Id */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 备注 */
  remark?: string;

  /**  */
  id?: string;
}

export interface CapitalIncomeRecordFromDonationDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 捐赠时间 */
  incomeDate?: Date;

  /** 捐赠方名称 */
  donatorName?: string;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  simpleDetail?: CapitalIncomeRecordFromDonationDetailDto;

  /**  */
  details?: CapitalIncomeRecordFromDonationDetailDto[];

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromDonationPagedResultRequestDto {
  /**  */
  incomeDate?: Date;

  /**  */
  incomeDateStart?: Date;

  /**  */
  incomeDateEnd?: Date;

  /**  */
  donatorName?: string;

  /**  */
  incomeType?: PayType;

  /**  */
  bankAccount?: string;

  /**  */
  totalIncomeMoney?: number;

  /**  */
  totalIncomeMoneyStart?: number;

  /**  */
  totalIncomeMoneyEnd?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /**  */
  currencyType?: CurrencyType;

  /**  */
  sorting?: string;

  /**  */
  skipCount?: number;

  /**  */
  maxResultCount?: number;
}

export interface CapitalIncomeRecordFromDonationDtoListResultDto {
  /**  */
  items?: CapitalIncomeRecordFromDonationDto[];
}

export interface CapitalIncomeRecordFromDonationDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CapitalIncomeRecordFromDonationDto[];
}

export interface CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto {
  /**  */
  id?: string;

  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /**  */
  incomeCategory?: IncomeCategory;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 摘要 */
  remark?: string;
}

export interface CapitalIncomeRecordFromOtherCreateOrUpdateDto {
  /** 收入时间 */
  incomeDate?: Date;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  details?: CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromOtherDetailDto {
  /**  */
  sequence?: number;

  /**  */
  capitalProperty?: CapitalProperty;

  /**  */
  incomeCategory?: IncomeCategory;

  /**  */
  currencyType?: CurrencyType;

  /** 金额 */
  money?: number;

  /** 摘要 */
  remark?: string;

  /**  */
  id?: string;
}

export interface CapitalIncomeRecordFromOtherDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 收入时间 */
  incomeDate?: Date;

  /**  */
  incomeType?: PayType;

  /** 付款账号 */
  payBankAccount?: string;

  /** 收款账号 */
  bankAccount?: string;

  /** 合计收入金额（元） */
  totalIncomeMoney?: number;

  /**  */
  simpleDetail?: CapitalIncomeRecordFromOtherDetailDto;

  /**  */
  details?: CapitalIncomeRecordFromOtherDetailDto[];

  /**  */
  id?: number;
}

export interface CapitalIncomeRecordFromOtherPagedResultRequestDto {
  /**  */
  incomeDate?: Date;

  /**  */
  incomeDateStart?: Date;

  /**  */
  incomeDateEnd?: Date;

  /**  */
  incomeType?: PayType;

  /**  */
  bankAccount?: string;

  /**  */
  totalIncomeMoney?: number;

  /**  */
  totalIncomeMoneyStart?: number;

  /**  */
  totalIncomeMoneyEnd?: number;

  /**  */
  incomeCategory?: IncomeCategory;

  /**  */
  currencyType?: CurrencyType;

  /**  */
  sorting?: string;

  /**  */
  skipCount?: number;

  /**  */
  maxResultCount?: number;
}

export interface CapitalIncomeRecordFromOtherDtoListResultDto {
  /**  */
  items?: CapitalIncomeRecordFromOtherDto[];
}

export interface CapitalIncomeRecordFromOtherDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CapitalIncomeRecordFromOtherDto[];
}

export interface CityCreateOrUpdateDto {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface CityDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CityDto[];
}

export interface CmsAnchorCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /** 锚点内容的建议宽度 */
  width?: number;

  /** 锚点内容的建议高度 */
  height?: number;

  /**  */
  id?: number;
}

export interface CmsAnchorDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /** 锚点内容的建议宽度 */
  width?: number;

  /** 锚点内容的建议高度 */
  height?: number;

  /**  */
  id?: number;
}

export interface CmsAnchorDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsAnchorDto[];
}

export interface CmsCategoryCreateOrUpdateDto {
  /**  */
  categoryCode?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  mobileTitleImagePath?: string;

  /**  */
  type?: CmsCategoryType;

  /**  */
  redirectUrl?: string;

  /**  */
  redirectUrlForWeixinMiniProgram?: string;

  /**  */
  id?: number;
}

export interface CmsCategoryDto {
  /**  */
  categoryCode?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  mobileTitleImagePath?: string;

  /**  */
  type?: CmsCategoryType;

  /**  */
  redirectUrl?: string;

  /**  */
  redirectUrlForWeixinMiniProgram?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsCategoryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsCategoryDto[];
}

export interface CmsContentDetailDto {
  /** 文章内容 */
  description?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  status?: CmsContentStatus;

  /**  */
  title?: string;

  /**  */
  isTop?: boolean;

  /** 当被设置置顶后，呈现的显示顺序 */
  topDisplayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /**  */
  cmsCategory?: CmsCategoryDto;

  /**  */
  cmsCategoryId?: number;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  source?: string;

  /**  */
  summary?: string;

  /** 阅读数量 */
  readCount?: number;

  /** 点赞数量 */
  praisedCount?: number;

  /**  */
  attachments?: AttachmentDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsContentCreateOrUpdateDto {
  /**  */
  title?: string;

  /**  */
  summary?: string;

  /**  */
  titleImageUrl?: string;

  /**  */
  cmsCategoryId?: number;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: Date;

  /** 是否直接发布 */
  isPublish?: boolean;

  /** 内容来源 */
  source?: string;

  /**  */
  description?: string;

  /**  */
  savedAttachments?: number[];

  /** 阅读数量 */
  readCount?: number;

  /** 点赞数量 */
  praisedCount?: number;

  /**  */
  id?: number;
}

export interface CmsContentDto {
  /**  */
  status?: CmsContentStatus;

  /**  */
  title?: string;

  /**  */
  isTop?: boolean;

  /** 当被设置置顶后，呈现的显示顺序 */
  topDisplayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /**  */
  cmsCategory?: CmsCategoryDto;

  /**  */
  cmsCategoryId?: number;

  /**  */
  author?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  source?: string;

  /**  */
  summary?: string;

  /** 阅读数量 */
  readCount?: number;

  /** 点赞数量 */
  praisedCount?: number;

  /**  */
  attachments?: AttachmentDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsContentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsContentDto[];
}

export interface CmsFriendLinkCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  link?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  logo?: string;

  /**  */
  id?: number;
}

export interface CmsFriendLinkDto {
  /**  */
  name?: string;

  /**  */
  link?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  logo?: string;

  /**  */
  id?: number;
}

export interface CmsFriendLinkDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsFriendLinkDto[];
}

export interface ChangeUiThemeInput {
  /**  */
  theme?: string;
}

export interface CustomFormLayoutDetailCreateOrUpdateDto {
  /** 显示顺序 */
  sequence?: number;

  /**  */
  parentId?: string;

  /**  */
  elementName?: string;

  /**  */
  elementContent?: string;

  /**  */
  textAlign?: string;

  /**  */
  elementAttributes?: object;

  /**  */
  id?: string;
}

export interface CustomFieldDataSourceCreateOrUpdateDto {
  /** 绑定的要显示的名称 */
  bindDisplayName?: string;

  /** 绑定的值 */
  bindValue?: string;

  /**  */
  dataSourceType?: CustomFormDataSourceType;

  /**  */
  fieldId?: string;

  /**  */
  dataSource?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldChooseValueCreateOrUpdateDto {
  /**  */
  displayName?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldCreateOrUpdateDto {
  /** 是否为动态字段 */
  isDynamic?: boolean;

  /**  */
  fieldName?: string;

  /**  */
  fieldDisplayName?: string;

  /**  */
  placeholder?: string;

  /**  */
  elementType?: string;

  /** 是否必填项 */
  required?: boolean;

  /**  */
  fieldValidators?: string[];

  /** 所在的布局 */
  layoutId?: string;

  /**  */
  dataSource?: CustomFieldDataSourceCreateOrUpdateDto;

  /** 可用来选择的自定义的列表值(仅当DataSource是CustomValues时，才有值) */
  chooseValues?: CustomFormFieldChooseValueCreateOrUpdateDto[];

  /**  */
  id?: string;
}

export interface CustomFormCreateOrUpdateDto {
  /**  */
  hostType?: CustomFormType;

  /** 宿主ID，可为空。当为空时，表示针对某类通用的。 */
  hostId?: string;

  /**  */
  title?: string;

  /**  */
  layout?: CustomFormLayoutDetailCreateOrUpdateDto[];

  /**  */
  fields?: CustomFormFieldCreateOrUpdateDto[];

  /**  */
  id?: number;
}

export interface CustomFormLayoutDetailDto {
  /**  */
  customFormId?: number;

  /** 显示顺序 */
  sequence?: number;

  /**  */
  parentId?: string;

  /**  */
  elementName?: string;

  /**  */
  elementContent?: string;

  /**  */
  textAlign?: string;

  /**  */
  elementAttributes?: object;

  /**  */
  id?: string;
}

export interface CustomFieldDataSourceDto {
  /**  */
  fieldId?: string;

  /** 绑定的要显示的名称 */
  bindDisplayName?: string;

  /** 绑定的值 */
  bindValue?: string;

  /**  */
  dataSourceType?: CustomFormDataSourceType;

  /**  */
  dataSource?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldChooseValueDto {
  /**  */
  displayName?: string;

  /**  */
  id?: string;
}

export interface CustomFormFieldDto {
  /**  */
  customFormId?: number;

  /** 是否为动态字段 */
  isDynamic?: boolean;

  /**  */
  fieldName?: string;

  /**  */
  fieldDisplayName?: string;

  /**  */
  placeholder?: string;

  /**  */
  elementType?: string;

  /** 是否必填项 */
  required?: boolean;

  /**  */
  fieldValidators?: string[];

  /** 所在的布局 */
  layoutId?: string;

  /**  */
  dataSource?: CustomFieldDataSourceDto;

  /** 可用来选择的自定义的列表值(仅当DataSource是CustomValues时，才有值) */
  chooseValues?: CustomFormFieldChooseValueDto[];

  /**  */
  id?: string;
}

export interface CustomFormDto {
  /**  */
  hostType?: CustomFormType;

  /** 宿主ID，可为空。当为空时，表示针对某类通用的。 */
  hostId?: string;

  /**  */
  title?: string;

  /**  */
  layout?: CustomFormLayoutDetailDto[];

  /**  */
  fields?: CustomFormFieldDto[];

  /**  */
  id?: number;
}

export interface CustomFormCopyInput {
  /** 宿主ID，可为空。当为空时，表示针对某类通用的。 */
  hostId?: string;

  /**  */
  id?: number;
}

export interface CustomFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CustomFormDto[];
}

export interface DataDictionaryCreateOrUpdateDto {
  /** 租户。 */
  tenantId?: number;

  /** 键名 */
  key?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  parentId?: number;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface DataDictionaryDto {
  /** 租户。 */
  tenantId?: number;

  /** 键名 */
  key?: string;

  /** 显示的名称 */
  displayName?: string;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  parentId?: number;

  /**  */
  id?: number;
}

export interface DataDictionarySyncInput {
  /**  */
  id?: number;

  /**  */
  syncMode?: DataDictionarySyncMode;
}

export interface DataDictionaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DataDictionaryDto[];
}

export interface DataPermissionLimitDto {
  /** 宿主ID，可以是具体的id,也可以是`all`这个关键字 */
  hostId?: string;

  /**  */
  hostType?: DataPermissionLimitHostType;

  /**  */
  targetType?: DataPermissionLimitTargetType;

  /** 被授权的对象 */
  targetId?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface DataPermissionLimitUpdateDto {
  /** 宿主ID，可以是具体的id,也可以是`all`这个关键字 */
  hostId?: string[];

  /**  */
  targetType?: DataPermissionLimitTargetType;

  /**  */
  hostType?: DataPermissionLimitHostType;

  /**  */
  targetId?: string[];

  /**  */
  id?: string;
}

export interface DepartmentCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface DepartmentDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface DepartmentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DepartmentDto[];
}

export interface SelectListGroup {
  /**  */
  disabled?: boolean;

  /**  */
  name?: string;
}

export interface SelectListItem {
  /**  */
  disabled?: boolean;

  /**  */
  group?: SelectListGroup;

  /**  */
  selected?: boolean;

  /**  */
  text?: string;

  /**  */
  value?: string;
}

export interface ExternalDueDiligenceRecordCreateOrUpdateDto {
  /**  */
  auditFlowId?: string;

  /**  */
  organizationUnitId?: number;

  /**  */
  projectId?: number;

  /**  */
  dueDiligenceTypeId?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  place?: string;

  /**  */
  members?: string;

  /**  */
  visitObjectJson?: string;

  /**  */
  orgInfo?: string;

  /**  */
  projectInfo?: string;

  /**  */
  omnibusOpinion?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  cancelRestartAudit?: boolean;

  /**  */
  id?: number;
}

export interface ExternalDueDiligenceRecordDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  projectId?: number;

  /**  */
  dueDiligenceTypeId?: number;

  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  place?: string;

  /**  */
  members?: string;

  /**  */
  visitObjectJson?: string;

  /**  */
  orgInfo?: string;

  /**  */
  projectInfo?: string;

  /**  */
  omnibusOpinion?: string;

  /**  */
  dueDiligenceType?: DataDictionaryDto;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  cancelRestartAudit?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ExternalDueDiligenceRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalDueDiligenceRecordDto[];
}

export interface ExternalDueDiligenceRecordDtoListResultDto {
  /**  */
  items?: ExternalDueDiligenceRecordDto[];
}

export interface ExternalMonthlyPlanDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 内容概述 */
  summary?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 详细地址 */
  place?: string;

  /** 预计人数 */
  peopleCount?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanCreateOrUpdateDto {
  /** 日期 */
  date?: Date;

  /**  */
  projectId?: number;

  /**  */
  details?: ExternalMonthlyPlanDetailCreateOrUpdateDto[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanDetailDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 内容概述 */
  summary?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 详细地址 */
  place?: string;

  /** 预计人数 */
  peopleCount?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanDto {
  /** 日期 */
  date?: Date;

  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  details?: ExternalMonthlyPlanDetailDto[];

  /**  */
  project?: ProjectDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface ExternalMonthlyPlanDtoListResultDto {
  /**  */
  items?: ExternalMonthlyPlanDto[];
}

export interface ExternalMonthlyPlanDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalMonthlyPlanDto[];
}

export interface ExternalMonthlySummarizeDetailDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /** 内容概述 */
  summary?: string;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 地点 */
  place?: string;

  /** 实到人数 */
  peopleCount?: number;

  /** 原因\/问题 */
  reason?: string;

  /** 对应策略 */
  tactics?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  savedAttachments?: number[];

  /**  */
  externalMonthlyPlanDetailId?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeDto {
  /** 日期 */
  date?: Date;

  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  details?: ExternalMonthlySummarizeDetailDto[];

  /** 面临的问题 */
  problem?: string;

  /** 所需资源 */
  needsResource?: string;

  /**  */
  project?: ProjectDto;

  /** 对应月计划Id */
  externalMonthlyPlanId?: number;

  /**  */
  externalMonthlyPlan?: ExternalMonthlyPlanDto;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /** 内容概述 */
  summary?: string;

  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  /**  */
  townId?: number;

  /** 地点 */
  place?: string;

  /** 实到人数 */
  peopleCount?: number;

  /** 原因\/问题 */
  reason?: string;

  /** 对应策略 */
  tactics?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  projectId?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  externalMonthlyPlanDetailId?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 日期 */
  date?: Date;

  /**  */
  details?: ExternalMonthlySummarizeDetailCreateOrUpdateDto[];

  /**  */
  externalMonthlyPlanId?: number;

  /** 面临的问题 */
  problem?: string;

  /** 所需资源 */
  needsResource?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalMonthlySummarizeDtoListResultDto {
  /**  */
  items?: ExternalMonthlySummarizeDto[];
}

export interface ExternalMonthlySummarizeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalMonthlySummarizeDto[];
}

export interface ProjectSpecificGoalDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /** 项目成效（具体目标） */
  effect?: string;

  /** 具体指标（具体目标描述） */
  norm?: string;

  /** 验证条件 */
  validateCondition?: string;

  /**  */
  id?: string;
}

export interface ExternalProjectSpecificPlanDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /**  */
  projectSpecificGoalId?: string;

  /** 标题 */
  title?: string;

  /** 具体内容 */
  summary?: string;

  /** 开始年月 */
  startDate?: Date;

  /** 结束年月 */
  endDate?: Date;

  /** 活动频次 */
  activityCount?: number;

  /** 参与人数 */
  peopleCount?: number;

  /** 预计产出 */
  output?: string;

  /**  */
  id?: string;
}

export interface ProjectRiskPlanDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /** 潜在风险 */
  risk?: string;

  /** 应对策略 */
  copingStrategy?: string;

  /**  */
  id?: string;
}

export interface ProjectMemberItemDto {
  /** 序列（顺序） */
  sequence?: number;

  /**  */
  projectId?: number;

  /** 姓名 */
  name?: string;

  /** 性别 */
  sex?: string;

  /** 年龄 */
  age?: number;

  /** 学历 */
  educationTypeId?: number;

  /**  */
  educationType?: DataDictionaryDto;

  /** 所学专业 */
  major?: string;

  /** 职业资质 */
  qualifications?: string;

  /** 团队分工 */
  projectRole?: string;

  /** 投入的时间精力比（%） */
  inputPercent?: number;

  /**  */
  id?: string;
}

export interface ExternalProjectBudgetItemDto {
  /**  */
  projectId?: number;

  /** 序列(显示顺序） */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /**  */
  id?: string;
}

export interface ExternalProjectCreateOrUpdateDto {
  /** 项目名称 */
  displayName?: string;

  /** 项目概述 */
  summary?: string;

  /** 项目关注的问题描述，以及对问题原因的分析 */
  problemDescription?: string;

  /** 项目实施地域的情况及服务对象的总体情况 */
  areaDescription?: string;

  /** 针对该问题，其他机构或政府已经采取的服务、政策及经验 */
  otherExperience?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 直接受益对象 */
  benefitObjectOfDirect?: string;

  /** 间接受益对象 */
  benefitObjectOfIndirect?: string;

  /** 总目标 */
  objectiveOfMain?: string;

  /** 项目成效及成功指标 */
  effects?: ProjectSpecificGoalDto[];

  /** 具体计划 */
  specificPlans?: ExternalProjectSpecificPlanDto[];

  /** 项目风险预案 */
  riskPlans?: ProjectRiskPlanDto[];

  /** 项目团队信息 */
  projectMemberItems?: ProjectMemberItemDto[];

  /** 项目负责人简介 */
  projectMangerInfo?: string;

  /** 项目预算明细 */
  budgetItems?: ExternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 实施地点 */
  place?: string;

  /** 总预算 */
  totalMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectDetailDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  projectTypes?: string;

  /** 项目名称 */
  displayName?: string;

  /** 项目概述 */
  summary?: string;

  /** 项目关注的问题描述，以及对问题原因的分析 */
  problemDescription?: string;

  /** 项目实施地域的情况及服务对象的总体情况 */
  areaDescription?: string;

  /** 针对该问题，其他机构或政府已经采取的服务、政策及经验 */
  otherExperience?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 直接受益对象 */
  benefitObjectOfDirect?: string;

  /** 间接受益对象 */
  benefitObjectOfIndirect?: string;

  /** 总目标 */
  objectiveOfMain?: string;

  /** 项目成效及成功指标 */
  effects?: ProjectSpecificGoalDto[];

  /** 具体计划 */
  specificPlans?: ExternalProjectSpecificPlanDto[];

  /** 项目风险预案 */
  riskPlans?: ProjectRiskPlanDto[];

  /** 项目团队信息 */
  projectMemberItems?: ProjectMemberItemDto[];

  /** 项目负责人简介 */
  projectMangerInfo?: string;

  /** 项目预算明细 */
  budgetItems?: ExternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProjectMonthlySummaizeDetailsDto {
  /** 活动照片 */
  attachment?: AttachmentDto[];

  /** 活动标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 执行概述 */
  summary?: string;

  /** 发布时间 */
  publishTime?: Date;
}

export interface ProjectMonthlySummaizeDetailsDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectMonthlySummaizeDetailsDto[];
}

export interface UpdateExternalProjectBudgetItemsDto {
  /** 项目预算明细 */
  budgetItems?: ExternalProjectBudgetItemDto[];

  /**  */
  id?: number;
}

export interface ExternalProjectDtoListResultDto {
  /**  */
  items?: ExternalProjectDto[];
}

export interface ExternalProjectDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectDto[];
}

export interface ExternalProjectChangeApplyCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /**  */
  sourceExternalProjectId?: number;

  /**  */
  targetExternalProjectId?: number;

  /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
  changeTypes?: string;

  /**  */
  savedChangeTypes?: string[];

  /** 变更时间 */
  changeDate?: Date;

  /** 变更原因 */
  changeReason?: string;

  /** 变更内容 */
  changeContent?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectChangeApplyDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  sourceExternalProjectId?: number;

  /**  */
  sourceExternalProject?: ExternalProjectDto;

  /**  */
  targetExternalProjectId?: number;

  /**  */
  targetExternalProject?: ExternalProjectDto;

  /** 变更类别,多选，数据字典(经费变更\/项目延期\/内容变更\/目标人群变更\/其他变) */
  changeTypes?: string;

  /**  */
  savedChangeTypes?: string[];

  /** 变更时间 */
  changeDate?: Date;

  /** 变更原因 */
  changeReason?: string;

  /** 变更内容 */
  changeContent?: string;

  /**  */
  organizationUnit?: OrganizationUnit;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectChangeApplyDtoListResultDto {
  /**  */
  items?: ExternalProjectChangeApplyDto[];
}

export interface ExternalProjectChangeApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectChangeApplyDto[];
}

export interface ExternalProjectActualBudgetItemDto {
  /**  */
  projectId?: number;

  /**  */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体计划Id */
  projectSpecificPlanId?: string;

  /** 具体目标Id */
  projectSpecificGoalId?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /** 计划预算明细项Id */
  budgetItemId?: number;

  /** 实际开支 */
  actualBudget?: number;

  /** 差额 */
  balance?: number;

  /** 实际占预算比例 */
  actualPercent?: number;

  /** 差异原因说明 */
  reason?: string;

  /**  */
  id?: string;
}

export interface CloseReportDetailDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  externalProjectActualBudgetItems?: ExternalProjectActualBudgetItemDto[];

  /**  */
  projectId?: number;

  /** 项目经费 */
  totalBudget?: number;

  /** 项目负责人 */
  projectManager?: string;

  /** 评估日期 */
  assessmentDate?: Date;

  /** 评估地点 */
  place?: string;

  /** 主要负责人员 */
  projectTeams?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 服务对象 */
  serviceObjects?: string;

  /** 服务区域 */
  serviceArea?: string;

  /** 评估形式 */
  assessmentType?: string;

  /** 1.	项目提出的问题，以及对问题真实性的验证 */
  problemsCheck?: string;

  /** 2.	回顾项目目标和执行计划 */
  implementReview?: string;

  /** 3.	初期该领域\/人群的状况，以及目前的状况 */
  situationCheck?: string;

  /** 4.	资助行为如何回应了差距\/问题 */
  subsidize?: string;

  /** 5.	被资助方、受益人和其他参与方的访谈情况 */
  interviewCheck?: string;

  /** 1.	总结的成功经验和模式 */
  experience?: string;

  /** 2.	出现的问题及解决策略 */
  problemsSolution?: string;

  /** 3.	成功\/典型案例 */
  successfulCase?: string;

  /** 1.	项目资金使用基本情况 */
  fundsUsage?: string;

  /** 2.	问题及建议 */
  financeProblems?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CloseReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 项目经费 */
  totalBudget?: number;

  /** 项目负责人 */
  projectManager?: string;

  /** 评估日期 */
  assessmentDate?: Date;

  /** 评估地点 */
  place?: string;

  /** 主要负责人员 */
  projectTeams?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 服务对象 */
  serviceObjects?: string;

  /** 服务区域 */
  serviceArea?: string;

  /** 评估形式 */
  assessmentType?: string;

  /** 1.	项目提出的问题，以及对问题真实性的验证 */
  problemsCheck?: string;

  /** 2.	回顾项目目标和执行计划 */
  implementReview?: string;

  /** 3.	初期该领域\/人群的状况，以及目前的状况 */
  situationCheck?: string;

  /** 4.	资助行为如何回应了差距\/问题 */
  subsidize?: string;

  /** 5.	被资助方、受益人和其他参与方的访谈情况 */
  interviewCheck?: string;

  /** 1.	总结的成功经验和模式 */
  experience?: string;

  /** 2.	出现的问题及解决策略 */
  problemsSolution?: string;

  /** 3.	成功\/典型案例 */
  successfulCase?: string;

  /** 1.	项目资金使用基本情况 */
  fundsUsage?: string;

  /** 2.	问题及建议 */
  financeProblems?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CloseReportDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目经费 */
  totalBudget?: number;

  /** 项目负责人 */
  projectManager?: string;

  /** 评估日期 */
  assessmentDate?: Date;

  /** 评估地点 */
  place?: string;

  /** 主要负责人员 */
  projectTeams?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 服务对象 */
  serviceObjects?: string;

  /** 服务区域 */
  serviceArea?: string;

  /** 评估形式 */
  assessmentType?: string;

  /** 1.	项目提出的问题，以及对问题真实性的验证 */
  problemsCheck?: string;

  /** 2.	回顾项目目标和执行计划 */
  implementReview?: string;

  /** 3.	初期该领域\/人群的状况，以及目前的状况 */
  situationCheck?: string;

  /** 4.	资助行为如何回应了差距\/问题 */
  subsidize?: string;

  /** 5.	被资助方、受益人和其他参与方的访谈情况 */
  interviewCheck?: string;

  /** 1.	总结的成功经验和模式 */
  experience?: string;

  /** 2.	出现的问题及解决策略 */
  problemsSolution?: string;

  /** 3.	成功\/典型案例 */
  successfulCase?: string;

  /** 1.	项目资金使用基本情况 */
  fundsUsage?: string;

  /** 2.	问题及建议 */
  financeProblems?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface CloseReportDtoListResultDto {
  /**  */
  items?: CloseReportDto[];
}

export interface CloseReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CloseReportDto[];
}

export interface ExternalProjectIntentionCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /**  */
  displayName?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 拟落地的区域 */
  place?: string;

  /** 受益人群 */
  benefitObject?: string;

  /** 拟申请项目概述 */
  summary?: string;

  /** 拟解决的问题与拟达到的目标 */
  solvedProblem?: string;

  /** 拟采取的策略\/开展的活动 */
  activityPlan?: string;

  /** 项目初步预算 */
  budgetPlan?: string;

  /** 执行机构过往经验 */
  executeExperience?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectIntentionDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 拟落地的区域 */
  place?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectIntentionDtoListResultDto {
  /**  */
  items?: ExternalProjectIntentionDto[];
}

export interface ExternalProjectIntentionDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectIntentionDto[];
}

export interface ExternalProjectPhaseItemDto {
  /**  */
  stage?: WorkflowOfProjectByExternal;

  /** 节点名称 */
  name?: string;

  /**  */
  status?: ProjectPhaseStatus;

  /** 跳转链接 */
  redirectUrl?: string[];
}

export interface ProjectMonthItemDto {
  /** 年份 */
  year?: number;

  /** 月份 */
  month?: number;

  /**  */
  status?: ProjectPhaseStatus;
}

export interface AttachmentDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AttachmentDto[];
}

export interface ExternalProjectProcessMonitoringCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /** 仅展示 */
  projectName?: string;

  /** 地点 */
  place?: string;

  /** 监测方式 */
  monitoringType?: string;

  /** 监测主题 */
  title?: string;

  /** 监测过程 */
  monitoringProcess?: string;

  /** 观察发现 */
  observe?: string;

  /** 给对方的反馈 */
  feedback?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectProcessMonitoringDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /**  */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /**  */
  cityId?: number;

  /**  */
  city?: CityDto;

  /**  */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /**  */
  townId?: number;

  /**  */
  town?: TownDto;

  /** 地点 */
  place?: string;

  /** 监测方式 */
  monitoringType?: string;

  /** 监测主题 */
  title?: string;

  /**  */
  monitoringProcess?: string;

  /**  */
  observe?: string;

  /**  */
  feedback?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ExternalProjectProcessMonitoringDtoListResultDto {
  /**  */
  items?: ExternalProjectProcessMonitoringDto[];
}

export interface ExternalProjectProcessMonitoringDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalProjectProcessMonitoringDto[];
}

export interface ProgressReportDetailDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 项目开展情况 */
  implementStatus?: string;

  /** 项目实施效果 */
  implementEffect?: string;

  /** 项目宣传情况 */
  publicityStatus?: string;

  /** 项目实施对组织自身的影响 */
  selfInfluence?: string;

  /** 项目创新性及可推广性 */
  innovation?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 下半年计划 */
  nextPlan?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProgressReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 项目开展情况 */
  implementStatus?: string;

  /** 项目实施效果 */
  implementEffect?: string;

  /** 项目宣传情况 */
  publicityStatus?: string;

  /** 项目实施对组织自身的影响 */
  selfInfluence?: string;

  /** 项目创新性及可推广性 */
  innovation?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 下半年计划 */
  nextPlan?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProgressReportDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 提交报告日期 */
  reportDate?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface ProgressReportDtoListResultDto {
  /**  */
  items?: ProgressReportDto[];
}

export interface ProgressReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProgressReportDto[];
}

export interface LineChartDto {
  /** 年份 */
  years?: number[];

  /** 项目数量 */
  projectCounts?: number[];

  /** 预算金额 */
  totalBudgets?: number[];

  /** 实际支出 */
  actualExpenses?: number[];

  /** 受益人次 */
  actualBeneficiaryCounts?: number[];

  /** 活动场次 */
  activityCounts?: number[];

  /** 覆盖区域数量 */
  townCounts?: number[];
}

export interface ExternalMicrocosmicDto {
  /** 项目名称 */
  projectName?: string;

  /** 开始时间 */
  startDate?: string;

  /** 结束时间 */
  endDate?: string;

  /**  */
  status?: WorkflowOfProjectByExternal;

  /** 活动场次 */
  activityCount?: number;

  /** 受益人次 */
  actualBeneficiaryCount?: number;

  /** 覆盖区域数量 */
  townCount?: number;

  /** 预算金额 */
  budget?: number;

  /** 实际支出 */
  actualExpenses?: number;
}

export interface ExternalMicrocosmicDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ExternalMicrocosmicDto[];
}

export interface ProjectOfNotCompleteDto {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  projectName?: string;

  /** 姓名 */
  name?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  email?: string;

  /**  */
  projectId?: number;

  /** 内部或外部的对应ID */
  hostId?: number;

  /**  */
  user?: User;
}

export interface ProjectOfNotCompleteDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectOfNotCompleteDto[];
}

export interface SummaryReportDetailDto {
  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 机构内部的决策机制 */
  decisionMechanism?: string;

  /** 合作机构Json */
  partnersJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 活动开展情况 */
  implementStatus?: string;

  /** 项目活动完成情况对照表 */
  implementStatusJson?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 服务对象反馈 */
  serviceObjectsFeedback?: string;

  /** 相关方反馈 */
  partnersFeedback?: string;

  /** 社会影响 */
  socialImpact?: string;

  /** 项目成果\/产出 */
  output?: string;

  /** 对执行机构的作用 */
  effectForSelf?: string;

  /** 对领域\/行业的作用 */
  effectForIndustry?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 整个项目执行的反思 */
  executeIntrospection?: string;

  /** 对基金会的建议和反馈 */
  feedbackForFoundation?: string;

  /**  */
  externalProjectActualBudgetItems?: ExternalProjectActualBudgetItemDto[];

  /** 实际开支 */
  actualBudget?: number;

  /** 差额 */
  balance?: number;

  /** 实际占预算比例 */
  actualPercent?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface SummaryReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 提交报告日期 */
  reportDate?: Date;

  /** 项目执行团队及人员分工Json */
  projectMemberJson?: string;

  /** 机构内部的决策机制 */
  decisionMechanism?: string;

  /** 合作机构Json */
  partnersJson?: string;

  /** 指标完成情况Json */
  projectSpecificGoalJson?: string;

  /** 介入方式 */
  interventions?: string;

  /** 活动开展情况 */
  implementStatus?: string;

  /** 项目活动完成情况对照表 */
  implementStatusJson?: string;

  /** 项目经费使用情况 */
  budgetUsedJson?: string;

  /** 服务对象反馈 */
  serviceObjectsFeedback?: string;

  /** 相关方反馈 */
  partnersFeedback?: string;

  /** 社会影响 */
  socialImpact?: string;

  /** 项目成果\/产出 */
  output?: string;

  /** 对执行机构的作用 */
  effectForSelf?: string;

  /** 对领域\/行业的作用 */
  effectForIndustry?: string;

  /** 项目执行中的问题和建议 */
  problemsJson?: string;

  /** 整个项目执行的反思 */
  executeIntrospection?: string;

  /** 对基金会的建议和反馈 */
  feedbackForFoundation?: string;

  /**  */
  externalProjectActualBudgetItems?: ExternalProjectActualBudgetItemDto[];

  /** 实际开支 */
  actualBudget?: number;

  /** 差额 */
  balance?: number;

  /** 实际占预算比例 */
  actualPercent?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface SummaryReportDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 提交报告日期 */
  reportDate?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface SummaryReportDtoListResultDto {
  /**  */
  items?: SummaryReportDto[];
}

export interface SummaryReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SummaryReportDto[];
}

export interface CacheFormPostDto {
  /**  */
  formType?: FormType;

  /** 数据 */
  data?: string;

  /** HostId */
  hostId?: string;
}

export interface InternalMonthlyPlanDetailCreateOrUpdateDto {
  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 内容概述 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /**  */
  savedPartnerOrgIds?: number[];

  /** 所需支持 */
  needSupport?: string;

  /** 备注 */
  remark?: string;

  /**  */
  projectId?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  enableRestartAudit?: boolean;
}

export interface InternalMonthlyPlanCreateOrUpdateDto {
  /** 日期 */
  date?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  projectId?: number;

  /**  */
  details?: InternalMonthlyPlanDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlyPlanDetailDto {
  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 内容概述 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /**  */
  savedPartnerOrgIds?: number[];

  /** 所需支持 */
  needSupport?: string;

  /** 备注 */
  remark?: string;

  /**  */
  projectId?: number;

  /**  */
  internalMonthlyPlanId?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlyPlanDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 日期 */
  date?: Date;

  /** 年 */
  year?: number;

  /** 对应的月份 */
  month?: number;

  /**  */
  details?: InternalMonthlyPlanDetailDto[];

  /**  */
  project?: ProjectDto;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface InternalMonthlyPlanDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalMonthlyPlanDto[];
}

export interface InternalMonthlyPlanDtoListResultDto {
  /**  */
  items?: InternalMonthlyPlanDto[];
}

export interface InternalMonthlySummarizeDetailCreateOrUpdateDto {
  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 原计划 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /**  */
  savedPartnerOrgIds?: number[];

  /** 执行概述 */
  executeSummary?: string;

  /** 受益人次 */
  benefitObjectCount?: number;

  /** 备注 */
  remark?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /**  */
  savedAttachments?: number[];

  /**  */
  projectId?: number;

  /**  */
  internalMonthlyPlanDetailId?: number;
}

export interface InternalMonthlySummarizeCreateOrUpdateDto {
  /** 日期 */
  date?: Date;

  /**  */
  auditFlowId?: string;

  /** 对应月计划Id */
  internalMonthlyPlanId?: number;

  /**  */
  projectId?: number;

  /**  */
  details?: InternalMonthlySummarizeDetailCreateOrUpdateDto[];

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlySummarizeDetailDto {
  /**  */
  sequence?: number;

  /** 地点 */
  place?: string;

  /** 标题 */
  title?: string;

  /** 开始日期 */
  startDate?: Date;

  /** 结束日期 */
  endDate?: Date;

  /** 原计划 */
  summary?: string;

  /** 合作方机构Id */
  partnerOrgId?: string;

  /** 执行概述 */
  executeSummary?: string;

  /** 受益人次 */
  benefitObjectCount?: number;

  /** 备注 */
  remark?: string;

  /** 发布时间 */
  publishTime?: Date;

  /**  */
  completeStatus?: ProjectMonthlyPlanCompleteStatus;

  /**  */
  internalMonthlySummarizeId?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  savedPartnerOrgIds?: number[];

  /**  */
  projectId?: number;

  /**  */
  internalMonthlyPlanDetailId?: number;

  /**  */
  id?: number;
}

export interface InternalMonthlySummarizeDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 日期 */
  date?: Date;

  /**  */
  year?: number;

  /**  */
  month?: number;

  /** 对应月计划Id */
  internalMonthlyPlanId?: number;

  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  details?: InternalMonthlySummarizeDetailDto[];

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface InternalMonthlySummarizeDtoListResultDto {
  /**  */
  items?: InternalMonthlySummarizeDto[];
}

export interface InternalMonthlySummarizeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalMonthlySummarizeDto[];
}

export interface InternalPersonalYearEndSummaryCreateOrUpdateDto {
  /** 年 */
  year?: number;

  /**  */
  role?: string;

  /**  */
  userName?: string;

  /**  */
  summary?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalPersonalYearEndSummaryDto {
  /** 年 */
  year?: number;

  /**  */
  role?: string;

  /**  */
  userName?: string;

  /**  */
  summary?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalPersonalYearEndSummaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalPersonalYearEndSummaryDto[];
}

export interface InternalPersonalYearEndSummaryDtoListResultDto {
  /**  */
  items?: InternalPersonalYearEndSummaryDto[];
}

export interface InternalProjectDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 项目概述 */
  summary?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 总预算 */
  totalMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  id?: number;
}

export interface InternalProjectDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalProjectDto[];
}

export interface InternalProjectBudgetItemDto {
  /**  */
  projectId?: number;

  /** 序列(显示顺序） */
  sequence?: number;

  /**  */
  projectBudgetCategory?: ProjectBudgetCategory;

  /**  */
  projectBudgetType?: ProjectBudgetType;

  /** 子项目内容（三级科目） */
  itemName?: string;

  /** 具体内容 */
  content?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算报价 */
  budgetQuotation?: number;

  /**  */
  projectBudgetCapitalSource?: ProjectBudgetCapitalSource;

  /**  */
  id?: string;
}

export interface InternalProjectCreateOrUpdateDto {
  /** 项目名称 */
  displayName?: string;

  /** 项目类型，如：,1,11,111, */
  projectTypes?: string;

  /** 项目概述 */
  summary?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 项目预算明细 */
  budgetItems?: InternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 项目变更保存 */
  isChangeEdit?: boolean;

  /**  */
  id?: number;
}

export interface InternalProjectDetailDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 项目名称 */
  displayName?: string;

  /** 项目类型，如：,1,11,111, */
  projectTypes?: string;

  /** 项目概述 */
  summary?: string;

  /** 实施地点 */
  place?: string;

  /** 项目开始日期 */
  startDate?: Date;

  /** 项目结束日期 */
  endDate?: Date;

  /** 项目预算明细 */
  budgetItems?: InternalProjectBudgetItemDto[];

  /** 向基金会申请资金 */
  applyMoney?: number;

  /** 自筹资金 */
  selfMoney?: number;

  /** 其他来源资金 */
  otherMoney?: number;

  /** 总预算 = 向基金会申请资金+自筹资金+其他来源资金 */
  totalMoney?: number;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  formId?: number;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /** 项目变更保存 */
  isChangeEdit?: boolean;

  /**  */
  id?: number;
}

export interface UpdateInternalProjectBudgetItemDto {
  /** 项目预算明细 */
  budgetItems?: InternalProjectBudgetItemDto[];

  /**  */
  id?: number;
}

export interface InternalProjectDtoListResultDto {
  /**  */
  items?: InternalProjectDto[];
}

export interface InternalProjectPhasesItemDto {
  /**  */
  stage?: WorkflowOfProjectByInternal;

  /** 节点名称 */
  name?: string;

  /**  */
  status?: ProjectPhaseStatus;

  /** 跳转链接 */
  redirectUrl?: string[];
}

export interface InternalMicrocosmicDto {
  /**  */
  projectName?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  status?: WorkflowOfProjectByInternal;

  /** 活动场次 */
  activityCount?: number;

  /** 受益人次 */
  actualBeneficiaryCount?: number;

  /** 受益人数 */
  beneficiaryCount?: number;

  /** 覆盖区域数量 */
  townCount?: number;

  /** 各类型合作方数量 */
  organizationUnitCount?: object;

  /** 预算金额 */
  budget?: number;

  /** 实际支出 */
  actualExpenses?: number;

  /** 服务次数 */
  serviceCount?: number;
}

export interface InternalMicrocosmicDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalMicrocosmicDto[];
}

export interface InternalProjectSummaryReportCreateOrUpdateDto {
  /**  */
  projectId?: number;

  /** 年度 */
  year?: number;

  /** 填写人 */
  userName?: string;

  /** 项目总结内容 */
  summary?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalProjectSummaryReportDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /** 年度 */
  year?: number;

  /** 填写人 */
  userName?: string;

  /** 项目总结内容 */
  summary?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface InternalProjectSummaryReportDtoListResultDto {
  /**  */
  items?: InternalProjectSummaryReportDto[];
}

export interface InternalProjectSummaryReportDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InternalProjectSummaryReportDto[];
}

export interface InventoryInFormCreateOrUpdateDto {
  /** 物资 */
  goods?: string;

  /** 品牌 */
  brand?: string;

  /** 型号 */
  model?: string;

  /** 规格 */
  specification?: string;

  /** 是否加入存货管理 */
  isJoinInventoryManage?: boolean;

  /** 详细数据 */
  detailJson?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface InventoryInFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 物资 */
  goods?: string;

  /** 品牌 */
  brand?: string;

  /** 型号 */
  model?: string;

  /** 规格 */
  specification?: string;

  /** 详细数据 */
  detailJson?: string;

  /** 是否加入存货管理 */
  isJoinInventoryManage?: boolean;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface InventoryInFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InventoryInFormDto[];
}

export interface InventoryInFormDtoListResultDto {
  /**  */
  items?: InventoryInFormDto[];
}

export interface InventoryOutFormCreateOrUpdateDto {
  /**  */
  inventoryInFormId?: number;

  /** 物资 */
  goods?: string;

  /** 物资关联 */
  goodsOther?: string;

  /** 品牌 */
  brand?: string;

  /** 型号 */
  model?: string;

  /** 单价 */
  unitPrice?: number;

  /** 详细数据 */
  detailJson?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface InventoryOutFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  inventoryInFormId?: number;

  /**  */
  inventoryInForm?: InventoryInFormDto;

  /** 物资 */
  goods?: string;

  /** 物资关联 */
  goodsOther?: string;

  /** 品牌 */
  brand?: string;

  /** 型号 */
  model?: string;

  /** 单价 */
  unitPrice?: number;

  /** 详细数据 */
  detailJson?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface InventoryOutFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: InventoryOutFormDto[];
}

export interface InventoryOutFormDtoListResultDto {
  /**  */
  items?: InventoryOutFormDto[];
}

export interface ItemBorrowingFormCreateOrUpdateDto {
  /** 借用物品清单 */
  itemsJson?: string;

  /** 借用事项说明 */
  description?: string;

  /** 预计归还时间 */
  backTime?: string;

  /** 实际归还时间 */
  actualBackTime?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface ItemBorrowingFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 借用物品清单 */
  itemsJson?: string;

  /** 借用事项说明 */
  description?: string;

  /** 预计归还时间 */
  backTime?: string;

  /** 实际归还时间 */
  actualBackTime?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  isProcessed?: boolean;

  /**  */
  processTime?: Date;

  /**  */
  processUserId?: number;

  /**  */
  id?: number;
}

export interface ItemBorrowingFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ItemBorrowingFormDto[];
}

export interface ItemBorrowingFormProcessInputDto {
  /** 实际归还时间 */
  actualBackTime?: string;

  /**  */
  id?: number;
}

export interface ItemBorrowingFormDtoListResultDto {
  /**  */
  items?: ItemBorrowingFormDto[];
}

export interface MatterAuditFormCreateOrUpdateDto {
  /** 报批部门 */
  department?: string;

  /** 事项 */
  matter?: string;

  /** 事项简要描述 */
  description?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface MatterAuditFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 报批部门 */
  department?: string;

  /** 事项 */
  matter?: string;

  /** 事项简要描述 */
  description?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  id?: number;
}

export interface MatterAuditFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: MatterAuditFormDto[];
}

export interface MatterAuditFormDtoListResultDto {
  /**  */
  items?: MatterAuditFormDto[];
}

export interface NotifyTemplateDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 评估计划模板名称 */
  title?: string;

  /** 评估计划模板内容（富文本） */
  content?: string;

  /**  */
  notifyTemplateType?: NotifyTemplateType;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface NotifyRecordDetailDto {
  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  project?: ProjectDto;

  /**  */
  notifyTemplate?: NotifyTemplateDto;

  /** 项目Id */
  hostId?: number;

  /**  */
  notifyType?: NotifyTemplateType;

  /** 通知模板Id */
  notifyTemplateId?: number;

  /** 通知标题 */
  title?: string;

  /** 通知内容（富文本） */
  content?: string;

  /**  */
  id?: number;
}

export interface NotifyRecordCreateOrUpdateDto {
  /** 项目Id */
  hostId?: number;

  /**  */
  notifyType?: NotifyTemplateType;

  /** 通知模板Id */
  notifyTemplateId?: number;

  /** 通知标题 */
  title?: string;

  /** 通知内容（富文本） */
  content?: string;

  /**  */
  id?: number;
}

export interface NotifyRecordDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目Id */
  hostId?: number;

  /**  */
  notifyType?: NotifyTemplateType;

  /** 通知模板Id */
  notifyTemplateId?: number;

  /** 通知标题 */
  title?: string;

  /** 通知内容（富文本） */
  content?: string;

  /** 发送时间 */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface ProjectAndOrgUnitDto {
  /**  */
  projectName?: string;

  /**  */
  orgName?: string;
}

export interface NotifyRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: NotifyRecordDto[];
}

export interface NotifyTemplateDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: NotifyTemplateDto[];
}

export interface NotifyTemplateCreateOrUpdateDto {
  /** 评估计划模板名称 */
  title?: string;

  /** 评估计划模板内容（富文本） */
  content?: string;

  /**  */
  notifyTemplateType?: NotifyTemplateType;

  /**  */
  id?: number;
}

export interface OrganizationUnitPagedResultRequestDto {
  /**  */
  displayName?: string;

  /** 机构的类型(数据字典的ID） */
  organizationType?: number;

  /**  */
  isApply?: boolean;

  /**  */
  status?: OrganizationStatus;

  /**  */
  principalName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /**  */
  sorting?: string;

  /**  */
  skipCount?: number;

  /**  */
  maxResultCount?: number;
}

export interface OrganizationUnitCreateOrUpdateDto {
  /**  */
  inviteCode?: string;

  /**  */
  logoImageUrl?: string;

  /**  */
  displayName?: string;

  /** 机构类型,数据字典Id */
  organizationType?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 地点 */
  place?: string;

  /** 负责人姓名 */
  principalName?: string;

  /** 主要负责人的手机号 */
  mobilePhone?: string;

  /** 主要负责人的邮箱 */
  emailAddress?: string;

  /** 联系人姓名 */
  contactName?: string;

  /** 联系人手机号 */
  contactMobilePhone?: string;

  /** 联系人邮箱 */
  contactEmailAddress?: string;

  /** 统一社会信用代码 */
  creditCode?: string;

  /** 合作方银行账号名称 */
  bankAccountName?: string;

  /** 开户行信息 */
  bankName?: string;

  /** 开户账号 */
  bankAccount?: string;

  /** 收件人 */
  receiverName?: string;

  /** 收件地址 */
  receiverAddress?: string;

  /** 收件电话 */
  receiverMobile?: string;

  /** 法人登记证书复印件 */
  certificateImagePath?: string;

  /**  */
  extensionData?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionObject?: object;

  /**  */
  formId?: number;

  /**  */
  id?: number;
}

export interface PartnerInviteDto {
  /** 被邀请的机构名称 */
  organizationName?: string;

  /**  */
  organizationType?: number;

  /**  */
  emailAddress?: string;

  /**  */
  mobile?: string;

  /** 邀约的说明内容 */
  content?: string;

  /**  */
  hasUsed?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface OrganizationUnitLoginAccountResult {
  /**  */
  userId?: number;

  /**  */
  userName?: string;

  /**  */
  password?: string;
}

export interface Int64EntityDto {
  /**  */
  id?: number;
}

export interface OrganizationUnitDtoListResultDto {
  /**  */
  items?: OrganizationUnitDto[];
}

export interface OrganizationUnitDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: OrganizationUnitDto[];
}

export interface PartnerCapitalApplyDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 资金用途 */
  purpose?: string;

  /** 单价（元） */
  unitPrice?: number;

  /** 数量 */
  number?: number;

  /** 单位 */
  unit?: string;

  /** 预算（元） */
  budgetQuotation?: number;

  /** 配比资金（元） */
  otherFund?: number;

  /** 配比资金来源 */
  otherFundSource?: string;

  /**  */
  id?: string;
}

export interface PartnerCapitalApplyCreateOrUpdateDto {
  /** 项目Id */
  projectId?: number;

  /** 机构联系人 */
  contactName?: string;

  /** 申请理由 */
  applyReason?: string;

  /** 资金预算明细 */
  partnerCapitalApplyDetails?: PartnerCapitalApplyDetailCreateOrUpdateDto[];

  /** 合计申请资金（元） */
  totalApplyMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface PartnerCapitalApplyDto {
  /** 项目Id */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 机构联系人 */
  contactName?: string;

  /** 申请理由 */
  applyReason?: string;

  /** 资金预算明细 */
  partnerCapitalApplyDetails?: PartnerCapitalApplyDetailCreateOrUpdateDto[];

  /** 合计申请资金（元） */
  totalApplyMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface PartnerCapitalApplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PartnerCapitalApplyDto[];
}

export interface PartnerCapitalApplyDtoListResultDto {
  /**  */
  items?: PartnerCapitalApplyDto[];
}

export interface PartnerCapitalApplySettlementDetailCreateOrUpdateDto {
  /**  */
  sequence?: number;

  /** 资金用途 */
  purpose?: string;

  /**  */
  purposeArray?: string[];

  /** 申请资金预算（元） */
  applyPrice?: number;

  /** 申请资金结算（元） */
  settlementPrice?: number;

  /** 备注 */
  remark?: string;

  /** 配比资金（元） */
  otherFund?: number;

  /** 配比资金来源 */
  otherFundSource?: string;

  /** 发票文件 */
  invoiceFile?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  attachments?: AttachmentDto[];

  /**  */
  id?: string;
}

export interface PartnerCapitalApplySettlementCreateOrUpdateDto {
  /** 项目Id */
  projectId?: number;

  /** 资金申请Id */
  partnerCapitalApplyId?: number;

  /** 申请理由 */
  applyReason?: string;

  /** 资金预算明细 */
  partnerCapitalApplySettlementDetails?: PartnerCapitalApplySettlementDetailCreateOrUpdateDto[];

  /** 合计申请资金（元） */
  totalApplyMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface PartnerCapitalApplySettlementDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目Id */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  partnerCapitalApplyId?: number;

  /**  */
  partnerCapitalApply?: PartnerCapitalApplyDto;

  /** 申请理由 */
  applyReason?: string;

  /** 项目资金结算明细 */
  partnerCapitalApplySettlementDetails?: PartnerCapitalApplySettlementDetailCreateOrUpdateDto[];

  /** 合计申请结算资金（元） */
  totalApplyMoney?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface PartnerCapitalApplySettlementDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PartnerCapitalApplySettlementDto[];
}

export interface PartnerCapitalApplySettlementDtoListResultDto {
  /**  */
  items?: PartnerCapitalApplySettlementDto[];
}

export interface PartnerInviteCreateOrUpdateDto {
  /** 被邀请的机构名称 */
  organizationName?: string;

  /** 机构类型,数据字典Id */
  organizationType?: number;

  /**  */
  emailAddress?: string;

  /**  */
  mobile?: string;

  /** 邀约的说明内容 */
  content?: string;

  /**  */
  id?: number;
}

export interface PartnerInviteDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PartnerInviteDto[];
}

export interface SummaryDto {
  /**  */
  totalBeneficiaries?: number;

  /**  */
  totalProjects?: number;

  /**  */
  totalAmount?: number;
}

export interface PermissionDefinitionCreateOrUpdateDto {
  /** 权限的键 */
  name?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  displayOrder?: number;

  /**  */
  url?: string;

  /** 是否不在菜单中显示 */
  isHiddenForMenu?: boolean;

  /**  */
  icon?: string;

  /**  */
  parentId?: number;

  /**  */
  permissionDefinitionScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface PermissionDefinitionDto {
  /** 权限的键 */
  name?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  displayOrder?: number;

  /**  */
  url?: string;

  /** 是否不在菜单中显示 */
  isHiddenForMenu?: boolean;

  /**  */
  icon?: string;

  /**  */
  parentId?: number;

  /**  */
  permissionDefinitionScopes?: PermissionDefinitionScope[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface StringStringKeyValuePair {
  /**  */
  key?: string;

  /**  */
  value?: string;
}

export interface PermissionDefinitionDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PermissionDefinitionDto[];
}

export interface PrivateCarPublicSubsidyFormCreateOrUpdateDto {
  /** 申请事由 */
  applyReason?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 开始-出发地 */
  startDeparture?: string;

  /** 开始-目的地 */
  startDestination?: string;

  /** 开始-公里数 */
  startKilometers?: number;

  /** 结束时间 */
  endTime?: Date;

  /** 结束-出发地 */
  endDeparture?: string;

  /** 结束-目的地 */
  endDestination?: string;

  /** 结束-公里数 */
  endKilometers?: number;

  /** 总公里数 */
  totalKilometers?: number;

  /** 补贴费用 */
  fee?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface PrivateCarPublicSubsidyFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 申请事由 */
  applyReason?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 开始-出发地 */
  startDeparture?: string;

  /** 开始-目的地 */
  startDestination?: string;

  /** 开始-公里数 */
  startKilometers?: number;

  /** 结束时间 */
  endTime?: Date;

  /** 结束-出发地 */
  endDeparture?: string;

  /** 结束-目的地 */
  endDestination?: string;

  /** 结束-公里数 */
  endKilometers?: number;

  /** 总公里数 */
  totalKilometers?: number;

  /** 补贴费用 */
  fee?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  isProcessed?: boolean;

  /**  */
  processTime?: Date;

  /**  */
  processUserId?: number;

  /**  */
  id?: number;
}

export interface PrivateCarPublicSubsidyFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: PrivateCarPublicSubsidyFormDto[];
}

export interface PrivateCarPublicSubsidyFormProcessInputDto {
  /** 结束时间 */
  endTime?: Date;

  /** 结束-出发地 */
  endDeparture?: string;

  /** 结束-目的地 */
  endDestination?: string;

  /** 结束-公里数 */
  endKilometers?: number;

  /** 总公里数 */
  totalKilometers?: number;

  /** 补贴费用 */
  fee?: number;

  /**  */
  id?: number;
}

export interface PrivateCarPublicSubsidyFormDtoListResultDto {
  /**  */
  items?: PrivateCarPublicSubsidyFormDto[];
}

export interface ProjectFullInfoDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectFullInfoDto[];
}

export interface ProjectBasicDetailUpdateDto {
  /** 项目图片 */
  titleImagePath?: string;

  /** 项目成果 */
  projectResult?: string;

  /** 项目类型，这个不用传，传数组即可 */
  projectTypes?: string;

  /** 中期报告提交时间 */
  progressReportDate?: Date;

  /** 末期报告提交时间 */
  summaryReportDate?: Date;

  /** 项目人员Id */
  projectManagerIds?: number[];

  /**  */
  projectTypesArrary?: string[];

  /**  */
  id?: number;
}

export interface ProjectOrganizationUnitMappingDto {
  /**  */
  projectId?: number;

  /** 合作机构Id */
  organizationUnitIds?: number[];
}

export interface ProjectCreateOrUpdateDto {
  /**  */
  id?: number;
}

export interface ProjectBeneficiaryOrganizationMappingDto {
  /** 项目与受益人关系表Id */
  projectBeneficiaryMappingId?: number;

  /** 机构Id */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  id?: number;
}

export interface ProjectBeneficiaryMappingDto {
  /**  */
  projectId?: number;

  /**  */
  project?: ProjectDto;

  /**  */
  beneficiaryId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目受益人所有机构（一对多） */
  projectBeneficiaryOrganizationMappings?: ProjectBeneficiaryOrganizationMappingDto[];

  /** 受益人所属机构名称 */
  beneficiaryOrganizationName?: string;

  /**  */
  extensionData?: string;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /**  */
  nationText?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface ProjectBeneficiaryMappingDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectBeneficiaryMappingDto[];
}

export interface ProjectBeneficiaryMappingDtoListResultDto {
  /**  */
  items?: ProjectBeneficiaryMappingDto[];
}

export interface ProjectBeneficiaryMappingCreateOrUpdateDto {
  /** 受益人ID */
  beneficiaryId?: number;

  /**  */
  projectId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  extensionObject?: object;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  extensionData?: string;

  /**  */
  formId?: number;

  /** 个案编号 */
  code?: string;

  /** 负责人 */
  principalName?: string;

  /** 负责人电话 */
  principalPhone?: string;

  /** 姓名 */
  realName?: string;

  /** 性别(男,女) */
  sex?: string;

  /** 出生日期 */
  birthday?: Date;

  /** 民族 */
  nation?: string;

  /** 联系电话 */
  phone?: string;

  /** 身份证号 */
  idNo?: string;

  /**  */
  provinceId?: number;

  /**  */
  provinceName?: string;

  /**  */
  cityId?: number;

  /**  */
  cityName?: string;

  /**  */
  areaId?: number;

  /**  */
  areaName?: string;

  /**  */
  townId?: number;

  /**  */
  townName?: string;

  /**  */
  areaSelect?: AreaSelectDto;

  /** 详细地址 */
  homeAddress?: string;

  /** 是否为建档立卡户 */
  isEnjoyOtherSalvation?: boolean;

  /** 建档立卡金额(元\/月) */
  otherSalvationAmount?: number;

  /** 是否享受低保政策 */
  isEnjoyTheSubsistenceAllowancePolicy?: boolean;

  /** 低保金额(元\/月) */
  subsistenceAllowanceAmount?: number;

  /**  */
  savedAttachments?: number[];

  /** 家庭成员情况 */
  familyMembersInfoList?: FamilyMembersInfoDto[];

  /**  */
  id?: number;
}

export interface UserOrganizationUnitInput {
  /** 关联ID */
  mappingIds?: number[];

  /** 机构ID */
  orgIds?: number[];
}

export interface ProjectInviteCreateDto {
  /** 项目名称 */
  displayName?: string;

  /** 是否内部项目 */
  isInternal?: boolean;

  /** 所属系列Id，数据字典,外部项目固定为行业支持 */
  projectSeriesId?: number;

  /** 所属年度 */
  year?: number;

  /** 邀约合作机构Id */
  organizationUnitId?: number[];

  /** 项目负责人Id */
  projectManagerIds?: number[];

  /**  */
  id?: number;
}

export interface ProjectSeriesShortDto {
  /** 系列名称 */
  title?: string;

  /**  */
  id?: number;
}

export interface UserDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isActive?: boolean;

  /**  */
  fullName?: string;

  /**  */
  lastLoginTime?: Date;

  /**  */
  creationTime?: Date;

  /**  */
  departmentId?: number;

  /**  */
  departmentName?: string;

  /**  */
  roleNames?: string[];

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  organizationUnitId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;
}

export interface ProjectInviteDto {
  /** 项目名称 */
  displayName?: string;

  /**  */
  project?: ProjectDto;

  /** 是否内部项目 */
  isInternal?: boolean;

  /**  */
  projectSeries?: ProjectSeriesShortDto;

  /** 所属年度 */
  year?: number;

  /** 邀约合作机构Id */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /**  */
  projectManagerList?: UserDto[];

  /**  */
  id?: number;
}

export interface ProjectInviteDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectInviteDto[];
}

export interface ProjectSeriesDto {
  /** 系列名称 */
  title?: string;

  /** 图片 */
  titleImagePath?: string;

  /** 是否公开显示 */
  isPublicShow?: boolean;

  /** 序号 */
  sequence?: number;

  /** 是否默认，默认时不可删除 */
  isDefault?: boolean;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  seriesType?: ProjectSeriesType;

  /** 项目成果 */
  achievement?: string;

  /**  */
  id?: number;
}

export interface ProjectSeriesDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProjectSeriesDto[];
}

export interface ProjectSeriesCreateOrUpdateDto {
  /** 系列名称 */
  title?: string;

  /** 图片 */
  titleImagePath?: string;

  /** 是否公开显示 */
  isPublicShow?: boolean;

  /** 序号 */
  sequence?: number;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  seriesType?: ProjectSeriesType;

  /** 项目成果 */
  achievement?: string;

  /**  */
  id?: number;
}

export interface ProvinceCreateOrUpdateDto {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface ProvinceDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProvinceDto[];
}

export interface RoleCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /** 是否为默认的角色 */
  isDefault?: boolean;

  /** 是否为固定角色 */
  isStatic?: boolean;

  /**  */
  grantedPermissions?: string[];

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  grantedPermissions?: string[];

  /**  */
  isStatic?: boolean;

  /**  */
  isDefault?: boolean;

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleListDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  isStatic?: boolean;

  /**  */
  isDefault?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleListDtoListResultDto {
  /**  */
  items?: RoleListDto[];
}

export interface PermissionDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  id?: number;
}

export interface PermissionDtoListResultDto {
  /**  */
  items?: PermissionDto[];
}

export interface CreateRoleDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  grantedPermissions?: string[];

  /**  */
  id?: number;

  /**  */
  isDefault?: boolean;

  /**  */
  isStatic?: boolean;

  /**  */
  roleScopes?: PermissionDefinitionScope[];
}

export interface GetRoleForEditOutput {
  /**  */
  role?: CreateRoleDto;

  /**  */
  permissions?: PermissionDefinitionDto[];

  /**  */
  grantedPermissions?: PermissionDefinitionDto[];
}

export interface RoleDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: RoleDto[];
}

export interface ApplicationInfoDto {
  /**  */
  version?: string;

  /**  */
  releaseDate?: Date;

  /**  */
  features?: object;
}

export interface UserLoginInfoDto {
  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  userName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  id?: number;
}

export interface TenantLoginInfoDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  id?: number;
}

export interface GetCurrentLoginInformationsOutput {
  /**  */
  application?: ApplicationInfoDto;

  /**  */
  user?: UserLoginInfoDto;

  /**  */
  tenant?: TenantLoginInfoDto;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /**  */
  permissions?: string[];

  /**  */
  roles?: string[];
}

export interface CmsAnchor {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  width?: number;

  /**  */
  height?: number;

  /**  */
  tenantId?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface Swiper {
  /**  */
  tenantId?: number;

  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /**  */
  displayName?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  anchor?: CmsAnchor;

  /**  */
  remark?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SwiperDto {
  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /** 名称 */
  displayName?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  remark?: string;

  /**  */
  anchor?: CmsAnchorDto;

  /**  */
  id?: number;
}

export interface SwiperDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SwiperDto[];
}

export interface SwiperCreateOrUpdateDto {
  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /** 名称 */
  displayName?: string;

  /**  */
  remark?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  id?: number;
}

export interface CreateTenantDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  adminEmailAddress?: string;

  /**  */
  connectionString?: string;

  /**  */
  isActive?: boolean;
}

export interface TenantDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  isActive?: boolean;

  /**  */
  id?: number;
}

export interface TenantDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantDto[];
}

export interface TestDetail {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  value?: string;

  /**  */
  id?: number;
}

export interface TestMainCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  title?: string;

  /**  */
  testDetails?: TestDetail[];

  /**  */
  id?: number;
}

export interface TestDetailDto {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  value?: string;

  /**  */
  id?: number;
}

export interface TestMainDto {
  /**  */
  name?: string;

  /**  */
  title?: string;

  /**  */
  testDetails?: TestDetailDto[];

  /**  */
  id?: number;
}

export interface TestMainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TestMainDto[];
}

export interface AuthenticateModel {
  /**  */
  userNameOrEmailAddress?: string;

  /**  */
  password?: string;

  /**  */
  rememberClient?: boolean;
}

export interface AuthenticateResultModel {
  /**  */
  accessToken?: string;

  /**  */
  encryptedAccessToken?: string;

  /**  */
  expireInSeconds?: number;

  /**  */
  userId?: number;
}

export interface ExternalLoginProviderInfoModel {
  /**  */
  name?: string;

  /**  */
  clientId?: string;
}

export interface ExternalAuthenticateModel {
  /**  */
  authProvider?: string;

  /**  */
  providerKey?: string;

  /**  */
  providerAccessCode?: string;
}

export interface ExternalAuthenticateResultModel {
  /**  */
  accessToken?: string;

  /**  */
  encryptedAccessToken?: string;

  /**  */
  expireInSeconds?: number;

  /**  */
  waitingForActivation?: boolean;

  /**  */
  user?: object;

  /**  */
  roleNames?: string[];
}

export interface WeChatAppletAuthenticate {
  /**  */
  code?: string;

  /**  */
  encryptedData?: string;

  /**  */
  iv?: string;

  /**  */
  session_key?: string;

  /**  */
  phoneNumberGetCode?: string;

  /**  */
  userLoginType?: string;
}

export interface TownCreateOrUpdateDto {
  /**  */
  areaId?: number;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface TownDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TownDto[];
}

export interface TrainChapterDto {
  /**  */
  id?: number;

  /**  */
  sequence?: number;

  /** 培训ID */
  trainId?: number;

  /** 标题 */
  name?: string;

  /** 简介 */
  summary?: string;

  /** 视频路径 */
  path?: string;
}

export interface TrainDto {
  /**  */
  titleImagePath?: string;

  /** 培训类型 */
  trainTypeId?: number;

  /**  */
  trainType?: DataDictionaryDto;

  /** 标题 */
  title?: string;

  /** 培训详情 */
  content?: string;

  /** 其他图片 */
  imagePaths?: string;

  /** 视频 */
  videoPath?: string;

  /** 已报名数量 */
  signUpCount?: number;

  /**  */
  status?: CmsContentStatus;

  /**  */
  statusText?: string;

  /**  */
  trainChapters?: TrainChapterDto[];

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface TrainCreateOrUpdateDto {
  /** 封面图 */
  titleImagePath?: string;

  /** 培训类型 */
  trainTypeId?: number;

  /** 标题 */
  title?: string;

  /** 培训详情 */
  content?: string;

  /** 其他图片 */
  imagePaths?: string;

  /** 视频 */
  videoPath?: string;

  /**  */
  trainChapterList?: TrainChapterDto[];

  /**  */
  isPubilsh?: boolean;

  /**  */
  id?: number;
}

export interface TrainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainDto[];
}

export interface TrainChartRecordDto {
  /**  */
  title?: string;

  /** 累计学习时长（分钟） */
  cumulativeLearningDurationMinutes?: number;

  /**  */
  cumulativeLearningDurationMinutesText?: string;

  /** 累计有效时长（分钟） */
  cumulativeEffectiveDurationMinutes?: number;

  /**  */
  cumulativeEffectiveDurationMinutesText?: string;

  /** 首次学习时间 */
  firstLookTime?: Date;

  /** 完成时间 */
  finishedTime?: Date;
}

export interface TrainRecordDto {
  /** 培训ID */
  trainId?: number;

  /**  */
  train?: TrainDto;

  /** 报名时间 */
  applyTime?: Date;

  /** 取消时间 */
  cancelTime?: Date;

  /**  */
  status?: TrainApplyStatus;

  /**  */
  chartRecords?: TrainChartRecordDto[];

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface TrainRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainRecordDto[];
}

export interface TrainRecordCreateOrUpdateDto {
  /** 培训ID */
  trainId?: number;

  /**  */
  id?: number;
}

export interface TrainVideoLearnInputDto {
  /** 培训ID */
  trainRecordId?: number;

  /**  */
  trainChapterId?: number;

  /** 时间节点 */
  time?: Date;
}

export interface TrainVideoLearnRecordInputDto {
  /** 学习记录ID */
  trainVideoLearnRecordID?: number;

  /** 时间节点 */
  time?: Date;
}

export interface TrainVideoLearnRecordDto {
  /**  */
  trainRecordId?: number;

  /**  */
  trainRecord?: TrainRecordDto;

  /** 视频开始播放时间 */
  startTime?: Date;

  /** 视频结束播放时间 */
  endTime?: Date;

  /** 完成时间 */
  finishTime?: Date;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface TrainVideoLearnRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TrainVideoLearnRecordDto[];
}

export interface TrainVideoLearnRecordCreateOrUpdateDto {
  /** 培训ID */
  trainRecordId?: number;

  /** 视频开始播放时间 */
  startTime?: Date;

  /** 视频结束播放时间 */
  endTime?: Date;

  /** 完成时间 */
  finishTime?: Date;

  /**  */
  id?: number;
}

export interface TravelApprovalFormCreateOrUpdateDto {
  /** 项目名称 */
  projectName?: string;

  /** 出行时间 */
  travelTime?: string;

  /** 目的地 */
  destination?: string;

  /** 出行人员 */
  travelers?: string;

  /** 出行方式 */
  travelMode?: string;

  /** 本期主题 */
  subject?: string;

  /** 行程安排及主要内容 */
  schedule?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  id?: number;
}

export interface TravelApprovalFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 项目名称 */
  projectName?: string;

  /** 出行时间 */
  travelTime?: string;

  /** 目的地 */
  destination?: string;

  /** 出行人员 */
  travelers?: string;

  /** 出行方式 */
  travelMode?: string;

  /** 本期主题 */
  subject?: string;

  /** 行程安排及主要内容 */
  schedule?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface TravelApprovalFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TravelApprovalFormDto[];
}

export interface TravelApprovalFormDtoListResultDto {
  /**  */
  items?: TravelApprovalFormDto[];
}

export interface CreateUserDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /** 所属部门 */
  departmentId?: number;

  /**  */
  isActive?: boolean;

  /**  */
  roleNames?: string[];

  /**  */
  password?: string;

  /**  */
  organizationUnitId?: number;

  /**  */
  phoneNumber?: string;
}

export interface RoleDtoListResultDto {
  /**  */
  items?: RoleDto[];
}

export interface ChangeUserLanguageDto {
  /**  */
  languageName?: string;
}

export interface UserDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserDto[];
}

export interface ChangePasswordDto {
  /**  */
  currentPassword?: string;

  /**  */
  newPassword?: string;
}

export interface ResetPasswordDto {
  /**  */
  adminPassword?: string;

  /**  */
  userId?: number;

  /**  */
  newPassword?: string;
}

export interface UserOrganizationUnitDto {
  /**  */
  user?: UserDto;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isActive?: boolean;

  /**  */
  fullName?: string;

  /**  */
  lastLoginTime?: Date;

  /**  */
  creationTime?: Date;

  /**  */
  departmentId?: number;

  /**  */
  departmentName?: string;

  /**  */
  roleNames?: string[];

  /**  */
  organizationUnitId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;
}

export interface UserOrganizationUnitDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserOrganizationUnitDto[];
}

export interface VehicleUsageApplicationFormCreateOrUpdateDto {
  /** 申请事由 */
  applyReason?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 开始-出发地 */
  startPlace?: string;

  /** 开始-备注 */
  startRemark?: string;

  /** 结束时间 */
  endTime?: Date;

  /** 结束-目的地 */
  endPlace?: string;

  /** 结束-备注 */
  endRemark?: string;

  /** 开始-公里数 */
  startKilometers?: number;

  /** 开始-公里数图片 */
  startKilometersImage?: string;

  /** 结束-公里数 */
  endKilometers?: number;

  /** 结束-公里数图片 */
  endKilometersImage?: string;

  /** 用车状况，车辆是否发生碰撞、刮擦或故障等 */
  situation?: string;

  /** 出发地 */
  departurePlace?: string;

  /** 返回地 */
  returnPlace?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface VehicleUsageApplicationFormDto {
  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitShortDto;

  /** 申请事由 */
  applyReason?: string;

  /** 开始时间 */
  startTime?: Date;

  /** 开始-出发地 */
  startPlace?: string;

  /** 开始-备注 */
  startRemark?: string;

  /** 结束时间 */
  endTime?: Date;

  /** 结束-目的地 */
  endPlace?: string;

  /** 结束-备注 */
  endRemark?: string;

  /** 开始-公里数 */
  startKilometers?: number;

  /** 开始-公里数图片 */
  startKilometersImage?: string;

  /** 结束-公里数 */
  endKilometers?: number;

  /** 结束-公里数图片 */
  endKilometersImage?: string;

  /** 用车状况，车辆是否发生碰撞、刮擦或故障等 */
  situation?: string;

  /** 出发地 */
  departurePlace?: string;

  /** 返回地 */
  returnPlace?: string;

  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  attachments?: AttachmentDto[];

  /**  */
  savedAttachments?: number[];

  /**  */
  isProcessed?: boolean;

  /**  */
  processTime?: Date;

  /**  */
  processUserId?: number;

  /**  */
  id?: number;
}

export interface VehicleUsageApplicationFormDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: VehicleUsageApplicationFormDto[];
}

export interface VehicleUsageApplicationFormProcessInputDto {
  /** 结束时间 */
  endTime?: Date;

  /** 返回地 */
  returnPlace?: string;

  /** 结束-备注 */
  endRemark?: string;

  /**  */
  id?: number;
}

export interface VehicleUsageApplicationFormDtoListResultDto {
  /**  */
  items?: VehicleUsageApplicationFormDto[];
}

export interface VolunteerCreateOrUpdateDto {
  /** 用户ID */
  userId?: number;

  /** \/姓名 */
  name?: string;

  /** 手机号 */
  phoneNumber?: string;

  /** 邮箱地址 */
  email?: string;

  /** 性别(男,女) */
  sex?: string;

  /**  */
  status?: VolunteerAuditStatus;

  /** 退回原因 */
  returnReason?: string;

  /**  */
  organizationUnitId?: number;

  /**  */
  id?: number;
}

export interface VolunteerDto {
  /** 用户ID */
  userId?: number;

  /**  */
  user?: UserMiniDto;

  /** \/姓名 */
  name?: string;

  /** 手机号 */
  phoneNumber?: string;

  /** 邮箱地址 */
  email?: string;

  /** 性别(男,女) */
  sex?: string;

  /**  */
  status?: VolunteerAuditStatus;

  /** 退回原因 */
  returnReason?: string;

  /**  */
  organizationUnitId?: number;

  /**  */
  organizationUnit?: OrganizationUnitDto;

  /**  */
  statusText?: string;

  /**  */
  creatorUser?: UserMiniDto;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface VolunteerAuditInputDto {
  /**  */
  volunteerId?: number;

  /** 是否通过 */
  isPass?: boolean;

  /** 退回原因 */
  returnReason?: string;
}

export interface VolunteerDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: VolunteerDto[];
}

export enum TenantAvailabilityState {
  'Available' = 'Available',
  'InActive' = 'InActive',
  'NotFound' = 'NotFound'
}

export enum AttachmentHostType {
  'Temp' = 'Temp',
  'CmsContentTitleImage' = 'CmsContentTitleImage',
  'CmsContentAttachment' = 'CmsContentAttachment',
  'EditorImage' = 'EditorImage',
  'CmsCategory' = 'CmsCategory',
  'Swiper' = 'Swiper',
  'OrganizationUnit' = 'OrganizationUnit',
  'FriendlyLinkLogo' = 'FriendlyLinkLogo',
  'Beneficiary_IdCard' = 'Beneficiary_IdCard',
  'Beneficiary_HouseholdRegister' = 'Beneficiary_HouseholdRegister',
  'Beneficiary_Home' = 'Beneficiary_Home',
  'Beneficiary_Other' = 'Beneficiary_Other',
  'External_Project_Monthly_Summarize_Attachment' = 'External_Project_Monthly_Summarize_Attachment',
  'External_Project_Monthly_Summarize_Photo' = 'External_Project_Monthly_Summarize_Photo',
  'External_Project_Intention' = 'External_Project_Intention',
  'Internal_Project_Monthly_Summarize_Attachment' = 'Internal_Project_Monthly_Summarize_Attachment',
  'External_Project' = 'External_Project',
  'External_Project_Process_Monitoring' = 'External_Project_Process_Monitoring',
  'External_Project_Progress_Report' = 'External_Project_Progress_Report',
  'External_Project_Summary_Report' = 'External_Project_Summary_Report',
  'External_Project_Close_Report' = 'External_Project_Close_Report',
  'Internal_Project' = 'Internal_Project',
  'Internal_Project_Summary_Report' = 'Internal_Project_Summary_Report',
  'Beneficiary' = 'Beneficiary',
  'ProjectSeries' = 'ProjectSeries',
  'External_Project_Monthly_Summarize_Detail_Attachment' = 'External_Project_Monthly_Summarize_Detail_Attachment',
  'Internal_Personal_Year_End_Summary' = 'Internal_Personal_Year_End_Summary',
  'Internal_ServiceRecord' = 'Internal_ServiceRecord',
  'PartnerCapitalApplySettlementDetail' = 'PartnerCapitalApplySettlementDetail',
  'VehicleUsageApplication' = 'VehicleUsageApplication'
}

export enum AuditFLowStatus {
  'Closed' = 'Closed',
  'Open' = 'Open'
}

export enum AuditFlowScope {
  'All' = 'All',
  'PartnerApply' = 'PartnerApply',
  'ExternalProjectIntention' = 'ExternalProjectIntention',
  'DueDiligenceRecord' = 'DueDiligenceRecord',
  'ExternalProject' = 'ExternalProject',
  'ExternalMonthlyPlan' = 'ExternalMonthlyPlan',
  'ExternalMonthlySummarize' = 'ExternalMonthlySummarize',
  'ExternalProjectAdjustment' = 'ExternalProjectAdjustment',
  'ExternalProjectProgressReport' = 'ExternalProjectProgressReport',
  'ExternalProjectProcessMonitoring' = 'ExternalProjectProcessMonitoring',
  'ExternalProjectSummaryReport' = 'ExternalProjectSummaryReport',
  'ExternalProjectCloseReport' = 'ExternalProjectCloseReport',
  'InternalProject' = 'InternalProject',
  'InternalMonthlyPlan' = 'InternalMonthlyPlan',
  'InternalMonthlySummarize' = 'InternalMonthlySummarize',
  'InternalProjectSummaryReport' = 'InternalProjectSummaryReport',
  'InternalPersonalYearEndSummary' = 'InternalPersonalYearEndSummary',
  'Beneficiary' = 'Beneficiary',
  'ServiceRecord' = 'ServiceRecord',
  'ProjectBebeficiaryMapping' = 'ProjectBebeficiaryMapping',
  'CapitalIncomeRecordFromDonation' = 'CapitalIncomeRecordFromDonation',
  'CapitalIncomeRecordFromOther' = 'CapitalIncomeRecordFromOther',
  'PartnerCapitalApply' = 'PartnerCapitalApply',
  'CapitalExpenseRecord' = 'CapitalExpenseRecord',
  'PartnerCapitalApplySettlement' = 'PartnerCapitalApplySettlement',
  'InventoryIn' = 'InventoryIn',
  'InventoryOut' = 'InventoryOut',
  'MatterAudit' = 'MatterAudit',
  'PrivateCarPublicSubsidy' = 'PrivateCarPublicSubsidy',
  'ItemBorrowing' = 'ItemBorrowing',
  'VehicleUsageApplication' = 'VehicleUsageApplication',
  'TravelApproval' = 'TravelApproval'
}

export enum AuditFlowType {
  'AuditOne' = 'AuditOne',
  'AuditAll' = 'AuditAll'
}

export enum AuditUserLogStatus {
  'Reject' = 'Reject',
  'Pass' = 'Pass',
  'Back' = 'Back',
  'Continue' = 'Continue'
}

export enum AuditUserLogType {
  'Unknow' = 'Unknow',
  'Project' = 'Project',
  'InternalProject' = 'InternalProject',
  'InternalMonthlyPlan' = 'InternalMonthlyPlan',
  'InternalMonthlySummarize' = 'InternalMonthlySummarize',
  'InternalProjectSummaryReports' = 'InternalProjectSummaryReports',
  'Beneficiary' = 'Beneficiary',
  'ProjectBeneficiaryMapping' = 'ProjectBeneficiaryMapping',
  'BeneficiaryServiceRecord' = 'BeneficiaryServiceRecord',
  'ExternalProject' = 'ExternalProject',
  'ExternalDueDiligenceRecord' = 'ExternalDueDiligenceRecord',
  'ExternalProjectIntention' = 'ExternalProjectIntention',
  'ExternalMonthlyPlan' = 'ExternalMonthlyPlan',
  'ExternalMonthlySummarize' = 'ExternalMonthlySummarize',
  'ExternalProjectCloseReport' = 'ExternalProjectCloseReport',
  'ExternalProjectProcessMonitoring' = 'ExternalProjectProcessMonitoring',
  'ExternalProjectProgressReport' = 'ExternalProjectProgressReport',
  'ExternalProjectSummaryReport' = 'ExternalProjectSummaryReport',
  'CapitalExpenseRecord' = 'CapitalExpenseRecord',
  'CapitalIncomeRecordFromDonation' = 'CapitalIncomeRecordFromDonation',
  'CapitalIncomeRecordFromOther' = 'CapitalIncomeRecordFromOther',
  'PartnerCapitalApply' = 'PartnerCapitalApply'
}

export enum ServiceRecordType {
  'Beneficiary' = 'Beneficiary',
  'School' = 'School'
}

export enum WorkflowOfProjectByExternal {
  'ProjectApproval' = 'ProjectApproval',
  'ProgressReport' = 'ProgressReport',
  'SummaryReport' = 'SummaryReport',
  'Closed' = 'Closed',
  'Termination' = 'Termination'
}

export enum ProjectServiceRecordCompleteStatus {
  'NotComplete' = 'NotComplete',
  'PartialComplete' = 'PartialComplete',
  'AllComplete' = 'AllComplete'
}

export enum PayType {
  'Cash' = 'Cash',
  'Transfer' = 'Transfer',
  'Material' = 'Material'
}

export enum ChangeState {
  'UnActive' = 'UnActive',
  'Active' = 'Active'
}

export enum ProjectBudgetCategory {
  'BusinessActivityCost' = 'BusinessActivityCost'
}

export enum ProjectBudgetType {
  'ActivityCost' = 'ActivityCost',
  'AdvertiseCost' = 'AdvertiseCost',
  'AdministrativeCost' = 'AdministrativeCost',
  'PersonnelSalary' = 'PersonnelSalary',
  'Other' = 'Other'
}

export enum ProjectBudgetCapitalSource {
  'Apply' = 'Apply',
  'Self' = 'Self',
  'Other' = 'Other'
}

export enum ImportResultType {
  'Inserted' = 'Inserted',
  'Updated' = 'Updated',
  'NotFound' = 'NotFound',
  'ValidationError' = 'ValidationError',
  'Error' = 'Error',
  'DataConvertFailed' = 'DataConvertFailed',
  'Exist' = 'Exist'
}

export enum ProjectSeriesType {
  'Internal' = 'Internal',
  'External' = 'External'
}

export enum CapitalProperty {
  'NonFinite' = 'NonFinite',
  'Finite' = 'Finite'
}

export enum CurrencyType {
  'RMB' = 'RMB'
}

export enum IncomeCategory {
  'Interest' = 'Interest',
  'Investment' = 'Investment',
  'Other' = 'Other'
}

export enum CmsCategoryType {
  'Content' = 'Content',
  'Article' = 'Article'
}

export enum CmsContentStatus {
  'Draft' = 'Draft',
  'Published' = 'Published',
  'Deleted' = 'Deleted'
}

export enum CustomFormType {
  'OrganizationUnit' = 'OrganizationUnit',
  'InternalProject' = 'InternalProject',
  'Beneficiary' = 'Beneficiary',
  'BeneficiaryServiceRecord' = 'BeneficiaryServiceRecord',
  'SchoolServiceRecord' = 'SchoolServiceRecord'
}

export enum CustomFormDataSourceType {
  'Enum' = 'Enum',
  'DataDictionary' = 'DataDictionary',
  'Api' = 'Api',
  'CustomValues' = 'CustomValues'
}

export enum DataDictionarySyncMode {
  'MinimumEffect' = 'MinimumEffect',
  'Force' = 'Force'
}

export enum DataPermissionLimitHostType {
  'CmsCategory' = 'CmsCategory'
}

export enum DataPermissionLimitTargetType {
  'User' = 'User',
  'Role' = 'Role'
}

export enum ProjectMonthlyPlanCompleteStatus {
  'None' = 'None',
  'Adjust' = 'Adjust',
  'Complete' = 'Complete'
}

export enum ProjectPhaseStatus {
  'NoTask' = 'NoTask',
  'None' = 'None',
  'Ongoing' = 'Ongoing',
  'DelayComplete' = 'DelayComplete',
  'Complete' = 'Complete'
}

export enum FormType {
  'LetterOfIntent' = 'LetterOfIntent',
  'ProjectPlanningPaper' = 'ProjectPlanningPaper',
  'ExternalProjectPlanChangeApplication' = 'ExternalProjectPlanChangeApplication',
  'InterimSummaryReport' = 'InterimSummaryReport',
  'ClosingSelfAssessmentReport' = 'ClosingSelfAssessmentReport',
  'ClosingEvaluationReport' = 'ClosingEvaluationReport'
}

export enum WorkflowOfProjectByInternal {
  'ProjectApproval' = 'ProjectApproval',
  'Closed' = 'Closed',
  'Termination' = 'Termination'
}

export enum NotifyTemplateType {
  'ProjectAssessmentPlan' = 'ProjectAssessmentPlan',
  'OrganizationInvestigation' = 'OrganizationInvestigation'
}

export enum OrganizationStatus {
  'Pending' = 'Pending',
  'Approved' = 'Approved',
  'Rejected' = 'Rejected'
}

export enum PermissionDefinitionScope {
  'Foundation' = 'Foundation',
  'Partner' = 'Partner'
}

export enum TrainApplyStatus {
  'Cancel' = 'Cancel',
  'Finished' = 'Finished'
}

export enum VolunteerAuditStatus {
  'Audit' = 'Audit',
  'Reject' = 'Reject',
  'Pass' = 'Pass'
}
