import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const volunteerRouter: RouteConfig[] = [
  {
    path: '/volunteer',
    component: Layout,
    name: 'volunteer',
    redirect: '/volunteer/list',
    meta: {
      title: '志愿者管理'
    },
    children: [
      {
        path: 'list',
        name: 'volunteer-list',
        component: () => import(/* webpackChunkName: "volunteer" */'@/views/volunteer/index.vue'),
        meta: {
          title: '志愿者管理'
        }
      },
    ]
  }
]


export default volunteerRouter;
